/* eslint-disable no-lonely-if */
import gsap from 'gsap';
import Config from './config.js';
import Utils from './utils.js';

const audioManagerComponent = {

  init() {
    window.audioManager = this;
    this.audioEnabled = true;

    // Music:
    this.battleMusicElement = document.querySelector('#epicMusicAudioElement'); // A-FRAME audio
    this.ambienceMusicElement = document.querySelector('#ambienceAudioElement'); // A-FRAME audio

    // Sounds:
    this.buttonSoundElement = document.querySelector('#button-click-audio'); // HTML audio

    this.allSoundsEntity = document.querySelector('#allSoundsEntity'); // A-FRAME audio

    // Events:
    window.addEventListener('click', (event) => {
      if (this.ambienceMusicElement.components.sound && !this.alreadyInited) {
        console.log('click - this.ambienceMusicElement.components.sound:', this.ambienceMusicElement.components.sound, ', isPlaying:', this.ambienceMusicElement.components.sound.isPlaying);
        this.playMenuMusic();
        this.alreadyInited = true;
      }
    });

    window.addEventListener('blur', (event) => {
      this.setAudioEnabled(false);
    });

    window.addEventListener('focus', (event) => {
      this.setAudioEnabled(true);
    });
  },

  startGame() {
    if (!this.allSound) {
      const allEntities = document.querySelectorAll('a-entity');
      this.allSound = [];
      allEntities.forEach((entity) => {
        if (entity.components.sound) this.allSound.push(entity);
      });
      this.allSound.forEach((soundElement) => {
        if (!soundElement.components.sound.originalVolume) soundElement.components.sound.originalVolume = soundElement.components.sound.data.volume;
      });
    }
    this.setAudioEnabled(this.audioEnabled);
  },

  setAudioEnabled(newStatus = true) {
    this.audioEnabled = newStatus;

    if (this.allSound && this.allSound.length > 0) {
      this.allSound[0].components.sound.listener.setMasterVolume(newStatus ? 1 : 0);
    }
    if (this.el.sceneEl && this.el.sceneEl.audioListener) {
      this.el.sceneEl.audioListener.setMasterVolume(newStatus ? 1 : 0);
    }
  },

  playSound(soundEvent = '') {
    if (Array.isArray(soundEvent)) { // If it's an array, get a random item
      soundEvent = soundEvent[THREE.MathUtils.randInt(0, soundEvent.length - 1)];
    }
    // console.log('playSound() - soundEvent:', soundEvent);
    this.allSoundsEntity.emit(soundEvent);
    for (let i = 0; i < this.allSoundsEntity.children.length; i++) {
      if (this.allSoundsEntity.children[i].components.sound.data.on === soundEvent) {
        this.allSoundsEntity.children[i].components.sound.stopSound();
        this.allSoundsEntity.children[i].emit(soundEvent);
        return;
      }
    }
  },

  stopSound(soundEvent = '') {
    for (let i = 0; i < this.allSoundsEntity.children.length; i++) {
      if (this.allSoundsEntity.children[i].components.sound.data.on === soundEvent) {
        this.allSoundsEntity.children[i].components.sound.stopSound();
        return;
      }
    }
  },

  playMenuMusic() {
    try {
      this.stopBattleMusic();
      this.ambienceMusicElement.setAttribute('sound', 'volume', 0.8);
      this.ambienceMusicElement.components.sound.playSound();
    } catch (error) {
      console.log('audioManager playMenuMusic() - error:', error);
    }
  },

  playBattleMusic() {
    try {
      this.stopAmbienceMusic();
      // this.battleMusicElement.setAttribute('sound', 'volume', 1);
      this.fadeAudioElement(this.battleMusicElement, 0.8);
      this.battleMusicElement.components.sound.playSound();
    } catch (error) {
      console.log('audioManager playBattleMusic() - error:', error);
    }
  },

  stopAmbienceMusic() {
    this.fadeAudioElement(this.ambienceMusicElement, 0, () => {
      this.ambienceMusicElement.components.sound.stopSound();
    });
  },

  stopBattleMusic() {
    this.fadeAudioElement(this.battleMusicElement, 0, () => {
      this.battleMusicElement.components.sound.stopSound();
    });
  },

  fadeAudioElement(audioElement, newVolume, callback = null) {
    if (audioElement.fadeVolumeTwn) audioElement.fadeVolumeTwn.kill();
    const twnObj = { value: audioElement.components.sound.data.volume };
    audioElement.fadeVolumeTwn = gsap.to(twnObj, {
      value: newVolume,
      duration: Config.musicFadeDuration,
      ease: 'linear',
      onUpdate: () => {
        audioElement.setAttribute('sound', 'volume', twnObj.value);
      },
      onComplete: () => {
        if (callback && typeof callback === 'function') callback.call();
      },
    });
  },

  playButtonSound() {
    if (!this.alreadyInited) return;
    try {
      //this.buttonSoundElement.pause();
      this.buttonSoundElement.currentTime = 0;
      this.buttonSoundElement.play();
    } catch (error) {
      console.log('playButtonSound() - error:', error);
    }
  },
};

export { audioManagerComponent };
