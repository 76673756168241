/* eslint-disable object-shorthand */
import gsap from 'gsap';
import Config from './config.js';
import Utils from './utils.js';

const laserComponent = {
  schema: {
    name: { default: 'laser' },
  },

  init() {
    this.originWorldPosition = new THREE.Vector3();
    this.targetWorldPosition = new THREE.Vector3();
    this.cameraObj = this.el.sceneEl.querySelector('#camera').object3D;
    this.targetObject = window.enemyMonsterRef.el.object3D;

    if (this.el.components['gltf-model'] && this.el.components['gltf-model'].model) {
      // Model was loaded before this init()
      this.modelFinishedLoading();
    } else {
      this.el.addEventListener('model-loaded', () => {
        this.modelFinishedLoading();
      });
    }
  },

  modelFinishedLoading() {
    this.el.object3D.traverse((node) => {
      if (node.material && node.material.color && !node.alreadyModified) {
        node.alreadyModified = true;
        node.material = new THREE.MeshBasicMaterial({
          color: 0xff00ff,
          // transparent: true,
          // opacity: 0.5,
        });
      }
    });
  },

  restartStatus() {
    this.el.object3D.scale.set(2.0, 2.0, 2.0);
    // this.el.object3D.position.copy(this.cameraObj.position);
  },

  appear() {
    this.restartStatus();
    window.audioManager.playSound('laserAudio');
    this.cameraObj.getWorldPosition(this.originWorldPosition);
    this.el.object3D.position.set(this.originWorldPosition.x, this.originWorldPosition.y - 0.1, this.originWorldPosition.z);

    this.targetObject.getWorldPosition(this.targetWorldPosition);
    this.targetWorldPosition.y += (14.0 * Config.modelScale * window.gameManager.getCurrentLevelData().enemyScale);

    this.el.object3D.lookAt(this.targetWorldPosition);

    this.moveTween = gsap.to(this.el.object3D.position, {
      x: this.targetWorldPosition.x,
      y: this.targetWorldPosition.y,
      z: this.targetWorldPosition.z,
      duration: this.el.object3D.position.distanceTo(this.targetWorldPosition) * 0.04,
      ease: 'none',
      /* onStart: () => {
      }, */
      onComplete: () => {
        this.disappear();
      },
    });
  },

  disappear() {
    gsap.to(this.el.object3D.scale, {
      x: 0.0001,
      y: 0.0001,
      z: 0.0001,
      duration: 0.05,
      ease: 'none',
      /* onStart: () => {
      }, */
      onComplete: () => {
        this.el.sceneEl.components.pool__laser.returnEntity(this.el); // Return to pool
      },
    });
  },
};

export { laserComponent };
