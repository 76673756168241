/* eslint-disable arrow-body-style */
import html2canvas from 'html2canvas';

export default class Utils {
  static checkParams() {
    // Get querystring:
    const querystring = window.location.search;
    // Create URLSearchParams from querystring:
    this.params = new URLSearchParams(querystring);
  }

  static paramIsEnabled(param = '') {
    if (!this.params) Utils.checkParams();
    // console.log('Utils - paramIsEnabled()', param, this.params.get(param) === 'true' || this.params.get(param) === '1');
    return this.params.get(param) === 'true' || this.params.get(param) === '1';
  }

  static getParamValue(param = '') {
    if (!this.params) Utils.checkParams();
    return this.params.get(param);
  }

  static getQueryString(key, default_) {
    if (default_ == null) {
      default_ = '';
    }
    key = key.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regex = new RegExp(`[\\?&]${key}=([^&#]*)`);
    const qs = regex.exec(window.location.href);
    if (qs == null) {
      return default_;
    } return qs[1];
  }

  static isUnsupportediOSBrowser() {
    const ua = window.navigator.userAgent;
    // var webkit = !!ua.match(/WebKit/i);
    const isChrome = ua.match(/Chrome/i) != null || ua.match(/CriOS/i) != null;
    const isFirefox = ua.match(/Firefox/i) != null || ua.match(/FxiOS/i) != null;
    const isSafari = ua.indexOf('Safari') != -1 && !isChrome;

    let isUnsupported = (Utils.isiOS() && (!isSafari && !isChrome && !isFirefox)) || Utils.getQueryString('unsupported') == 1;
    if (Utils.paramIsEnabled('bypassSupportedBrowser')) isUnsupported = false;
    // window.console.log('Utils - isUnsupportediOSBrowser() - isChrome', isChrome, ', isFirefox:', isFirefox, ', isSafari:', isSafari, '  -   isUnsupported:', isUnsupported);
    return isUnsupported;
    // return Utils.isiOS() && !iOSSafari;
  }

  static isUnsupportedNonIOSBrowser() {
    const ua = window.navigator.userAgent;
    return Utils.getQueryString('unsupported') == 1;
  }

  static isiOS() {
    const ua = window.navigator.userAgent;
    const isSafari = ua.indexOf('Safari') != -1;
    const isAndroid = ua.match(/Android/i) != null;
    if (isAndroid) return false;

    const isiOSTouch = isSafari && navigator.maxTouchPoints > 1;
    const result = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || isiOSTouch;
    // console.log('Utils - isiOS() - result:', result);
    return result;
  }

  static isAndroid() {
    const ua = window.navigator.userAgent;
    const isAndroid = ua.match(/Android/i) != null;
    // alert(ua);
    return isAndroid;
  }

  static isUnsupportedAndroidBrowser() {
    const ua = window.navigator.userAgent;

    // List the browser that are safe:
    const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const isChrome = ua.match(/Chrome/i) || ua.match(/CriOS/i);
    const isOpera = ua.indexOf('OP') > -1;
    const isEdge = ua.indexOf('Edg') > -1;
    const isKindle = ua.match(/Kindle/i) || ua.match(/Silk/i) || ua.match(/KFTT/i) || ua.match(/KFOT/i) || ua.match(/KFJWA/i) || ua.match(/KFJWI/i) || ua.match(/KFSOWI/i) || ua.match(/KFDOWI/i) || ua.match(/KFTHWA/i) || ua.match(/KFTHWI/i) || ua.match(/KFAPWA/i) || ua.match(/KFAPWI/i);

    let isUnsupported = (!isFireFox && !isChrome) || isEdge || isOpera || isKindle || Utils.getQueryString('unsupported') == 1;
    if (Utils.paramIsEnabled('bypassSupportedBrowser')) isUnsupported = false;
    console.log('Utils - isUnsupportedAndroidBrowser() - isUnsupported:', isUnsupported);
    return isUnsupported;
  }

  static detectTouchscreen() {
    let result = false;
    if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
      // if Pointer Events are supported, just check maxTouchPoints
      if (navigator.maxTouchPoints > 0) {
        result = true;
      }
    } else {
      // no Pointer Events...
      if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // check for any-pointer:coarse which mostly means touchscreen
        result = true;
      } else if (window.TouchEvent || ('ontouchstart' in window)) {
        // last resort - check for exposed touch events API / event handler
        result = true;
      }
    }
    return result;
  }

  static mobilecheck() {
    // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Safari/537.36
    const ua = navigator.userAgent;
    const winDesktop = ua.match(/Windows/i) != null || ua.match(/Win64/i) != null;
    const iOS = Utils.isiOS();
    const isiPad = ua.match(/iPad/i) != null;
    const isiPhone = !!((ua.match(/iPhone/i) != null || Utils.getQueryString('isiPhone') == 'true'));
    const isiPhoneX = (isiPhone && window.innerHeight >= 635);
    const isiPod = ua.match(/iPod/i) != null;
    const isAndroid = ua.match(/Android/i) != null;
    const isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);
    const isAndroidTablet = (isAndroid && ua.match(/Mobile/i) == null); // || getQueryString("isAndroidTablet") == "true" ) ? true : false,,
    const isAndroidPhone = !!((isAndroid && !isAndroidTablet));
    const isTablet = isiPad || isAndroidTablet;
    const isIE11 = (Object.hasOwnProperty.call(window, 'ActiveXObject') && !window.ActiveXObject); // detect 10- using user agent, 11 with feature detection,
    const isIE = ua.match(/MSIE/i) != null || ua.match(/Trident/i) != null || isIE11; // detect 10- using user agent, 11 with feature detection,
    const isChrome = ua.match(/Chrome/i) != null;
    const isIosChrome = ua.match('CriOS') != null;
    const isSafari = ua.indexOf('Safari') != -1 && !isChrome;
    const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const clickEvent = (isiPad || isiPhone || isAndroid || isMobileWebkit) ? 'touchend' : 'click';
    const isiOSTouch = isSafari && navigator.maxTouchPoints > 1;
    const multitouch = navigator.maxTouchPoints > 1;
    const isTouch = Utils.detectTouchscreen();
    const isMobile = (isTouch || iOS || isiPad || isiOSTouch || isiPhone || isiPod || isAndroid || isMobileWebkit || multitouch); // || getQueryString("isMobile") == "true" ) ? true : false,

    // window.console.debug("winDesktop", winDesktop);
    // window.console.log('Utils - mobilecheck() - winDesktop:', winDesktop, ', isMobile:', isMobile);
    if (winDesktop) return false;

    return isMobile;
  }

  static getRandomInt(min = 0, max = 1) {
    return Math.round(Math.random() * (max - min) + min); // max inclusive
  }

  static getRandomFloat(min = 0, max = 1) {
    return Math.random() * (max - min) + min;
  }

  static isLocalhost() {
    const res = location.hostname.split('.')[0] === '192' || location.hostname === 'localhost';
    return res;
  }

  static supportsHEVCAlpha() {
    const ua = window.navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(window.navigator.mediaCapabilities && window.navigator.mediaCapabilities.decodingInfo);
    const isSafari = ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1) && ua.indexOf('version/') != -1;
    return isSafari && hasMediaCapabilities;
  }

  static getRandomPositionInCircle(center, radius, convertToLocal = false, parent = null) {
    // Generate a random angle in radians between 0 and 2π
    const angle = Math.random() * 2 * Math.PI;

    // Calculate the new position using the given radius
    const x = center.x + radius * Math.cos(angle);
    const z = center.z + radius * Math.sin(angle);

    // Create the new position vector
    let position = new THREE.Vector3(x, center.y, z);

    // Convert to local position if needed
    if (convertToLocal && parent) {
      position = parent.worldToLocal(position);
    }

    return position;
  }

  static getInterpolatedPosition(center, currentPosition, targetPosition, t = 0.5, convertToLocal = false, parent = null) {
    // Linearly interpolate between current and target positions
    const interpolatedPosition = new THREE.Vector3().lerpVectors(currentPosition, targetPosition, t);

    // Calculate direction from center to interpolated position
    const direction = new THREE.Vector3().subVectors(interpolatedPosition, center).normalize();

    // Calculate the blue position maintaining the same radius
    const radius = currentPosition.distanceTo(center);
    let position = new THREE.Vector3().copy(center).add(direction.multiplyScalar(radius));

    // Convert to local position if needed
    if (convertToLocal && parent) {
      position = parent.worldToLocal(position);
    }

    console.log('Utils - getInterpolatedPosition - currentPosition, targetPosition:', currentPosition, targetPosition, ', position:', position);
    return position;
  }

  static getMiddlePositionOnCircumference(centerPosition, positionA, positionB) {
    // Create vectors from the positions
    const center = new THREE.Vector3(centerPosition.x, centerPosition.y, centerPosition.z);
    const posA = new THREE.Vector3(positionA.x, positionA.y, positionA.z);
    const posB = new THREE.Vector3(positionB.x, positionB.y, positionB.z);

    // Calculate the radius as the distance from center to positionA
    const radius = (center.distanceTo(posA) + center.distanceTo(posB)) * 0.5;

    // Calculate the midpoint between positionA and positionB
    const midpoint = new THREE.Vector3().addVectors(posA, posB).multiplyScalar(0.5);

    // Calculate the direction from the center to the midpoint
    const direction = new THREE.Vector3().subVectors(midpoint, center).normalize();

    // Project the midpoint onto the circumference
    const positionOnCircumference = new THREE.Vector3().copy(direction).multiplyScalar(radius).add(center);

    // Ensure the point lies on the XZ plane (Y coordinate of centerPosition)
    positionOnCircumference.y = center.y;

    console.log('Utils - getMiddlePositionOnCircumference - centerPosition:', centerPosition, ', positionA:', positionA, ', positionOnCircumference:', positionOnCircumference);
    return positionOnCircumference;
  }

  static getPositionOnCircumference(centerPosition, positionA, positionB, lerpFactor = 0.5) {
    // console.log('centerPosition:', centerPosition, 'positionA:', positionA, 'positionB:', positionB, 'lerpFactor:', lerpFactor);
    // Create vectors from the positions
    const center = new THREE.Vector3(centerPosition.x, centerPosition.y, centerPosition.z);
    const posA = new THREE.Vector3(positionA.x, positionA.y, positionA.z);
    const posB = new THREE.Vector3(positionB.x, positionB.y, positionB.z);

    // Calculate the radius as the distance from center to positionA
    const distanceA = center.distanceTo(posA);
    const distanceB = center.distanceTo(posB);
    let radius = (distanceA + distanceB) * 0.5;
    // radius = Math.min(distanceB, radius);
    // console.log('distanceA:', distanceA, 'distanceB:', distanceB, 'radius:', radius);

    // Interpolate between positionA and positionB using the given lerpFactor
    const interpolatedPosition = new THREE.Vector3().lerpVectors(posA, posB, lerpFactor);

    // Calculate the direction from the center to the interpolated position
    const direction = new THREE.Vector3().subVectors(interpolatedPosition, center).normalize();

    // Project the interpolated position onto the circumference
    const positionOnCircumference = new THREE.Vector3().copy(direction).multiplyScalar(radius).add(center);

    // Ensure the point lies on the XZ plane (Y coordinate of centerPosition)
    positionOnCircumference.y = center.y;

    return positionOnCircumference;
  }

  static getMiddlePositionOnCircumferenceCross(centerPosition, positionA, positionB) {
    // Create vectors from the positions
    const center = new THREE.Vector3(centerPosition.x, centerPosition.y, centerPosition.z);
    const posA = new THREE.Vector3(positionA.x, positionA.y, positionA.z);
    const posB = new THREE.Vector3(positionB.x, positionB.y, positionB.z);

    // Calculate the radius as the distance from center to positionA
    const radius = (center.distanceTo(posA) + center.distanceTo(posB)) * 0.5;

    // Calculate the vector from A to B
    const vectorAB = new THREE.Vector3().subVectors(posB, posA).normalize();

    // Calculate the vector from center to A
    const vectorCA = new THREE.Vector3().subVectors(posA, center).normalize();

    // Get the normal vector by taking the cross product of vectorAB and vectorCA
    const normal = new THREE.Vector3().crossVectors(vectorAB, vectorCA).normalize();

    // Ensure the normal vector lies on the XZ plane (Y coordinate of centerPosition)
    normal.y = 0;

    // Calculate the perpendicular direction from the center
    const perpendicularDirection = new THREE.Vector3().crossVectors(normal, vectorCA).normalize();

    // Calculate the position on the circumference at 90 degrees from midpoint
    const positionOnCircumference = new THREE.Vector3().copy(perpendicularDirection).multiplyScalar(radius).add(center);

    // Ensure the point lies on the XZ plane (Y coordinate of centerPosition)
    positionOnCircumference.y = center.y;

    return positionOnCircumference;
  }

  static getPositionTowards(origin, target, distance) {
    // Create Vector3 instances for origin and target if they are not already
    const originVec = (origin instanceof THREE.Vector3) ? origin : new THREE.Vector3(origin.x, origin.y, origin.z);
    const targetVec = (target instanceof THREE.Vector3) ? target : new THREE.Vector3(target.x, target.y, target.z);

    // Calculate the direction vector from origin to target
    const direction = new THREE.Vector3().subVectors(targetVec, originVec).normalize();

    // Scale the direction vector by the given distance
    const scaledDirection = direction.multiplyScalar(distance);

    // Add the scaled direction vector to the origin to get the new position
    const newPosition = new THREE.Vector3().addVectors(originVec, scaledDirection);

    return newPosition;
  }

  static async getElementAsCanvas(element) {
    const canvas = await html2canvas(element, {
      backgroundColor: '#000000',
      scale: 1,
    });
    return canvas;
  }

  static async saveElementScreenshot(element) {
    const canvas = await Utils.getElementAsCanvas(element);

    Utils.saveCanvasScreenshot(canvas);
  }

  static async saveCanvasScreenshot(canvas) {
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL('image/jpeg', 0.85);

    // Create a link element
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'Metallic_Menace_Score.jpeg';

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }

  static async shareContent(options) {
    if (navigator.share) {
      try {
        await navigator.share(options);
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  }
}
