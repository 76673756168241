/* eslint-disable object-shorthand */
import gsap from 'gsap';
import Config from './config.js';
import Utils from './utils.js';

const damageBadgeComponent = {
  schema: {
    name: { default: 'damageBadge' },
  },

  init() {
    this.originWorldPosition = new THREE.Vector3();
    this.cameraObj = this.el.sceneEl.querySelector('#camera').object3D;
    this.enemyObject = window.enemyMonsterRef.el.object3D;
  },

  restartStatus() {
    this.el.object3D.scale.set(1.0, 1.0, 1.0);
    if (this.animTween) this.animTween.kill();
    if (this.disappearTwn) this.disappearTwn.kill();
  },

  appear() {
    this.restartStatus();
    this.enemyObject.getWorldPosition(this.originWorldPosition);
    this.el.object3D.position.set(
      this.originWorldPosition.x + THREE.MathUtils.randFloat(-0.2, 0.2),
      this.originWorldPosition.y + THREE.MathUtils.randFloat(-0.2, 0.2) + (14.0 * Config.modelScale * window.gameManager.getCurrentLevelData().enemyScale),
      this.originWorldPosition.z + THREE.MathUtils.randFloat(-0.2, 0.2),
    );
    this.el.object3D.lookAt(this.cameraObj.position);

    this.animTween = gsap.to(this.el.object3D.position, {
      y: '+=0.7',
      duration: 0.9,
      ease: 'power2.out',
      onComplete: () => {
        //this.disappear();
      },
    });
    setTimeout(() => {
      this.disappear();
    }, this.animTween.duration() * 0.8 * 1000);
  },

  disappear() {
    this.disappearTwn = gsap.to(this.el.object3D.scale, {
      x: 0.0001,
      y: 0.0001,
      z: 0.0001,
      duration: 0.3,
      ease: 'sine.in',
      /* onStart: () => {
      }, */
      onComplete: () => {
        this.el.sceneEl.components.pool__damage_badge.returnEntity(this.el); // Return to pool
      },
    });
  },
};

export { damageBadgeComponent };
