/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
import gsap from 'gsap';
import Config from './config.js';
import Utils from './utils.js';

const enemyMonsterComponent = {
  schema: {
    name: { default: 'monster' },
  },

  init() {
    window.enemyMonsterRef = this;
    this.el.object3D.scale.set(0.00001, 0.00001, 0.00001);

    this.consecutiveAttacks = 0;
    this.targetPosition = new THREE.Vector3();
    this.targetPositionLocal = new THREE.Vector3();
    this.aCameraWorldPosition = new THREE.Vector3();
    this.movementTargetWorldPosition = new THREE.Vector3();
    this.elWorldPosition = new THREE.Vector3();
    this.attackEnabled = true;

    // Animations
    this.introAnimationClip = 'INTRO_anim';
    this.idleAnimationClip = 'Idle_1';
    this.movementAnimationClip = 'Legs_move';
    this.attackAnimationClip = 'Attack_3';
    this.damagedAnimationClip = 'Hit_3';
    this.defeatedAnimationClip = 'Hit_2';

    this.aCamera = this.el.sceneEl.querySelector('#camera');
    this.threeCamera = this.aCamera.getObject3D('camera');
    this.sceneOrigin = document.getElementById('sceneOrigin');
    this.reflectionSphere = this.el.sceneEl.querySelector('#reflectionSphere');

    const shadow = document.querySelector('#monsterShadow');
    shadow.components.material.material.depthWrite = false;
    shadow.object3D.renderOrder = -1;

    const iosDeviceMapping = new Map([
      ['320x480', { name: 'IPhone 4S, 4, 3GS, 3G, 1st gen', tier: 1 }],
      ['320x568', { name: 'IPhone 5, SE 1st Gen,5C, 5S', tier: 1 }],
      ['375x667', { name: 'IPhone SE 2nd Gen, 6, 6S, 7, 8', tier: 1 }],
      ['375x812', { name: 'IPhone X, XS, 11 Pro, 12 Mini, 13 Mini', tier: 2 }],
      ['390x844', { name: 'IPhone 13, 13 Pro, 12, 12 Pro', tier: 2 }],
      ['414x736', { name: 'IPhone 8+', tier: 1 }],
      ['414x896', { name: 'IPhone 11, XR, XS Max, 11 Pro Max', tier: 2 }],
      ['428x926', { name: 'IPhone 13 Pro Max, 12 Pro Max', tier: 2 }],
      ['476x847', { name: 'IPhone 7+, 6+, 6S+', tier: 1 }],
      ['744x1133', { name: 'IPad Mini 6th Gen', tier: 1 }],
      ['768x1024', { name: 'IPad Mini (5th Gen), IPad (1-6th Gen), iPad Pro (1st Gen 9.7), Ipad Mini (1-4), IPad Air(1-2)  ', tier: 1 }],
      ['810x1080', { name: 'IPad 7-9th Gen', tier: 1 }],
      ['820x1180', { name: 'iPad Air (4th gen)', tier: 1 }],
      ['834x1194', { name: 'iPad Pro (3-5th Gen 11)', tier: 1 }],
      ['834x1112', { name: 'iPad Air (3rd gen), iPad Pro (2nd gen 10.5)', tier: 1 }],
      ['1024x1366', { name: 'iPad Pro (1-5th Gen 12.9)', tier: 1 }],
    ]);
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const device = iosDeviceMapping.get(screenResolution) || { name: 'Not detected', tier: 2 };
    console.log(`Device name: ${device.name}  |||  Tier: ${device.tier}`);

    this.monsterModelGlb = this.el.querySelector('#monsterModelGlb');
    this.monsterModelGlb.initialPosZ = this.monsterModelGlb.object3D.position.z;
    // this.monsterModelGlb.setAttribute('gltf-model', device.tier > 1 ? '#monster-glb-highpoly' : '#monster-glb-lowpoly');
    this.monsterModelGlb.setAttribute('gltf-model', '#monster-glb-lowpoly');
    if (this.monsterModelGlb && this.monsterModelGlb.components['gltf-model'] && this.monsterModelGlb.components['gltf-model'].model) {
      // Model was loaded before this init()
      this.monsterModelFinishedLoading();
    } else {
      this.monsterModelGlb.addEventListener('model-loaded', () => {
        this.monsterModelFinishedLoading();
      });
    }
    this.monsterModelGlb.addEventListener('animation-finished', (action, direction) => {
      this.animationFinished(action, direction);
    });

    this.subscribeToEvents();

    if (Utils.paramIsEnabled('enableDebug')) {
      const debugBoxElement = document.createElement('a-box');
      debugBoxElement.setAttribute('material', { color: '#2DEFBE' });
      debugBoxElement.setAttribute('position', { x: 0, y: 0, z: 0 });
      debugBoxElement.setAttribute('scale', { x: 0.05, y: 4, z: 0.05 });
      this.el.appendChild(debugBoxElement);

      ///////////
      this.boxElement = document.createElement('a-box');
      this.boxElement.setAttribute('material', { color: '#10E078' });
      this.boxElement.setAttribute('scale', { x: 0.1, y: 0.1, z: 0.1 });
      this.el.parentEl.appendChild(this.boxElement);

      this.boxElementMid = document.createElement('a-box');
      this.boxElementMid.setAttribute('material', { color: '#53AD08' });
      this.boxElementMid.setAttribute('scale', { x: 0.1, y: 0.1, z: 0.1 });
      this.el.parentEl.appendChild(this.boxElementMid);

      this.boxElementEnd = document.createElement('a-box');
      this.boxElementEnd.setAttribute('material', { color: '#2F10E0' });
      this.boxElementEnd.setAttribute('scale', { x: 0.1, y: 0.1, z: 0.1 });
      this.el.parentEl.appendChild(this.boxElementEnd);
      ///////////

      this.movementLine = new THREE.LineLoop(new THREE.BufferGeometry(), new THREE.LineBasicMaterial({ color: 0xffffaa }));
      this.movementLine.position.y = 0.02;
      this.movementLine.frustumCulled = false;
      this.el.parentEl.object3D.add(this.movementLine);
    }
  },

  subscribeToEvents() {
    this.el.sceneEl.addEventListener('onSetPaused', this.onSetPaused.bind(this));
  },

  onSetPaused(evt) {
    this.monsterModelGlb.setAttribute('animation-mixer', {
      timeScale: evt.detail.isPaused ? 0 : this.currentAnimationTimescale,
    });
  },

  tick(time, deltaTime) {
    // console.log('enemyMonsterComponent tick() - time, deltaTime:', time, deltaTime);
  },

  initMonster(level) {
    this.currentLevel = level;
    this.modelScale = window.gameManager.getCurrentLevelData().enemyScale;
    this.monsterModelGlb.object3D.scale.set(Config.modelScale, Config.modelScale, Config.modelScale);
  },

  monsterModelFinishedLoading() {
    this.monsterModelGlb.object3D.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        if (!child.material.alreadyAdjusted) {
          child.material.alreadyAdjusted = true;
          this.matRef = child.material;
          // child.material.envMap = null;
          // child.material.envMapIntensity = 0;
          child.material.envMap = this.reflectionSphere.object3D.children[0].material.envMap;
          // child.material.metalness = Math.min(child.material.metalness + 0.1, 1);
          // child.material.roughness = 0.05;
          // child.material.flatShading = true;

          child.material.emissiveMap = null;
          child.material.emissive.set(0, 0, 0);
          child.material.normalScale.set(1, 1);
          child.material.vertexColors = false;
          child.material.metalness = 0.9;
          child.material.roughness = 0.13;

          // Update normal map:
          const normalTexture = new THREE.Texture(this.el.sceneEl.querySelector('#monster-normal-texture'));
          normalTexture.encoding = 3000;
          normalTexture.flipY = false;
          normalTexture.needsUpdate = true;
          child.material.normalMap = normalTexture;

          child.material.needsUpdate = true;
        }
      }
    });

    this.setAnimation(this.idleAnimationClip, true);
  },

  updateColor() {
    const newTexture = new THREE.Texture(this.el.sceneEl.querySelector(window.gameManager.getCurrentLevelData().monsterModelTexture));
    newTexture.encoding = 3001;
    newTexture.flipY = false;
    newTexture.needsUpdate = true;
    // this.oldTexture = this.matRef.map;
    if (this.matRef.map) this.matRef.map.dispose();
    this.newTexture = newTexture;
    this.matRef.map = newTexture;
    this.matRef.needsUpdate = true;
  },

  setAnimation(animationClip = '', repeat = false, timeScale = 1, clampWhenFinished = false) {
    // console.warn('setAnimation - animationClip:', animationClip, ', animation-mixer:', this.monsterModelGlb.getAttribute('animation-mixer'));
    this.currentAnimationTimescale = timeScale;

    // Check and reset animation if it's the same as before
    if (this.monsterModelGlb.getAttribute('animation-mixer') && animationClip === this.monsterModelGlb.getAttribute('animation-mixer').clip) {
      this.monsterModelGlb.setAttribute('animation-mixer', {
        clip: '',
      });
    }

    // Set new animation
    this.monsterModelGlb.setAttribute('animation-mixer', {
      clip: animationClip,
      loop: repeat ? 'repeat' : 'once',
      timeScale: timeScale,
      startAt: 0,
      clampWhenFinished: clampWhenFinished,
      // crossFadeDuration: 0.4,
    });

    // Fix position offset in 3D model
    this.monsterModelGlb.object3D.position.z = animationClip === this.attackAnimationClip ? this.monsterModelGlb.initialPosZ - 0.9 : this.monsterModelGlb.initialPosZ;

    // Audio
    if (window.audioManager) {
      if (animationClip === this.movementAnimationClip) window.audioManager.playSound('monsterMovingAudio');
      else window.audioManager.stopSound('monsterMovingAudio');
    }
  },

  showHideMonster(newValue = true) {
    if (newValue) {
      window.enemyMonsterRef.updateColor();
      this.setAnimation(this.introAnimationClip, false, Utils.paramIsEnabled('fastAnimation') ? 5 : 1);
      window.audioManager.playSound('groundBreakAudio');
    }
    gsap.to(this.el.object3D.scale, {
      x: newValue ? this.modelScale : 0.00001,
      y: newValue ? this.modelScale : 0.00001,
      z: newValue ? this.modelScale : 0.00001,
      duration: newValue ? 0.5 : 0.5,
      delay: newValue ? 0.1 : 0,
      ease: newValue ? 'back.out' : 'back.in',
      onStart: () => {
      },
      onComplete: () => {
        if (newValue) {
          // this.startAI();
        }
      },
    });
  },

  startAI() {
    this.setAttackEnabled(true);
    this.el.components['look-at'].setTrackingEnabled(true);
  },

  moveToPlayer() {
    if (!this.attackEnabled) return;
    this.consecutiveAttacks = 0;
    this.aCamera.object3D.getWorldPosition(this.aCameraWorldPosition);
    this.aCameraWorldPosition.y = 0;
    this.sceneOrigin.object3D.getWorldPosition(this.movementTargetWorldPosition);
    this.movementTargetWorldPosition.y = 0;
    this.el.object3D.getWorldPosition(this.elWorldPosition);

    let enemyDistance = window.gameManager.getCurrentLevelData().enemyDistance;
    let subDistance = Utils.getParamValue('subDistance');
    if (subDistance) subDistance = parseFloat(subDistance);
    if (typeof subDistance === 'number') {
      subDistance = Math.min(subDistance, enemyDistance);
      enemyDistance -= subDistance;
    }
    const newPosition = Utils.getPositionTowards(
      this.aCameraWorldPosition,
      this.movementTargetWorldPosition,
      enemyDistance,
    );

    this.targetPosition = Utils.getRandomPositionInCircle(
      newPosition,
      0.8, //window.gameManager.getCurrentLevelData().enemyDistance,
      // true,
      // this.el.object3D.parent,
    );
    // this.targetPosition.set(1, 0, 2); ////////////////////// REMOVE
    // console.log('1 - this.targetPosition:', this.targetPosition);
    this.targetPositionLocal.copy(this.el.object3D.parent.worldToLocal(this.targetPosition.clone()));
    this.targetPositionLocal.y = 0;
    // console.log('2 - this.targetPosition:', this.targetPosition);

    ////
    this.midPosition = Utils.getPositionOnCircumference(
      this.movementTargetWorldPosition,
      this.elWorldPosition,
      this.targetPosition,
    );
    // console.log('3 - this.targetPosition:', this.targetPosition);
    this.midPosition = this.el.object3D.parent.worldToLocal(this.midPosition);
    // console.log('moveToPlayer - this.targetPositionLocal:', this.targetPositionLocal);

    if (this.boxElement) this.boxElement.setAttribute('position', { x: this.midPosition.x, y: 0, z: this.midPosition.z });
    if (this.boxElementMid) this.boxElementMid.setAttribute('position', { x: this.midPosition.x, y: 0, z: this.midPosition.z });
    if (this.boxElementEnd) this.boxElementEnd.setAttribute('position', { x: this.targetPositionLocal.x, y: 0, z: this.targetPositionLocal.z });

    // console.log('moveToPlayer - this.targetPositionLocal:', this.targetPositionLocal);
    if (this.moveTween) this.moveTween.kill();
    /* this.moveTween = gsap.to(this.el.object3D.position, {
      // x: this.targetPositionLocal.x,
      // y: this.targetPositionLocal.y,
      // z: this.targetPositionLocal.z,
      motionPath: {
        path: [
          // { x: this.el.object3D.position.x, y: this.targetPositionLocal.y, z: this.el.object3D.position.z },
          { x: this.midPosition.x, y: this.el.object3D.y, z: this.midPosition.z },
          { x: this.targetPositionLocal.x, y: this.el.object3D.y, z: this.targetPositionLocal.z },
        ],
        // type: 'cubic',
      },
      duration: this.el.object3D.position.distanceTo(this.targetPositionLocal) / window.gameManager.getCurrentLevelData().enemyMovementSpeed,
      ease: 'sine.inOut',
      onStart: () => {
        // this.setAnimation(this.movementAnimationClip, true);
      },
      onComplete: () => {
        this.movementComplete();
      },
    }); */
    /* this.el.object3D.position.copy(this.midPosition);
    setTimeout(() => {
      this.el.object3D.position.copy(this.targetPositionLocal);
    }, 2000);
    setTimeout(() => {
      this.movementComplete();
    }, 4000); */

    this.pointInCurve = new THREE.Vector3();

    const somePoints = [
      this.el.object3D.position.clone(),
      this.midPosition,
      this.targetPositionLocal,
    ];

    const curve = new THREE.CatmullRomCurve3(somePoints,
      false, // closed: true
      'catmullrom', // curveType: centripetal, chordal and catmullrom
      0.5, // tension: 0.5
    );

    if (this.movementLine) {
      const points = curve.getPoints(40);
      this.movementLine.geometry.setFromPoints(points);
    }

    const twnObj = { value: 0 };
    setTimeout(() => {
      this.moveTween = gsap.to(twnObj, {
        // duration: this.el.object3D.position.distanceTo(this.targetPositionLocal) / window.gameManager.getCurrentLevelData().enemyMovementSpeed,
        duration: curve.getLength() / window.gameManager.getCurrentLevelData().enemyMovementSpeed,
        value: 1,
        // repeat: -1,
        ease: 'sine.inOut',
        onStart: () => {
          this.setAnimation(this.movementAnimationClip, true);
        },
        onUpdate: () => {
          // console.log('onUpdate - twnObj:', twnObj);
          this.pointInCurve = curve.getPoint(twnObj.value);
          this.el.object3D.position.set(this.pointInCurve.x, this.pointInCurve.y, this.pointInCurve.z);
          if (this.boxElement) this.boxElement.object3D.position.set(this.pointInCurve.x, this.pointInCurve.y, this.pointInCurve.z);
        },
        onComplete: () => {
          this.movementComplete();
        },
      });
    }, 1);
  },

  movementComplete() {
    if (this.attackEnabled) {
      this.attack();
    }
    else {
      this.setAnimation(this.idleAnimationClip, true);
    }
  },

  attack() {
    // console.log('attack()', this.el.sceneEl.components);
    this.consecutiveAttacks += 1;
    this.setAnimation(this.attackAnimationClip, false, 1, true);

    if (this.attackTimer) clearTimeout(this.attackTimer);
    this.attackTimer = setTimeout(() => { // Wait for animation to spawn the projectile
      this.trySendProjectile();

      if (this.doubleAttackTimer) clearTimeout(this.doubleAttackTimer);
      this.doubleAttackTimer = setTimeout(() => {
        const chanceToDoubleAttack = Math.random();
        if (chanceToDoubleAttack < 0.4) {
          this.trySendProjectile();
        }
      }, 300);
    }, 0);
  },

  trySendProjectile() {
    const projectileEl = this.el.sceneEl.components.pool__enemy_projectile.requestEntity();
    if (projectileEl && projectileEl.components['enemy-projectile']) {
      projectileEl.play();
      // console.log('attack - projectileEl:', projectileEl, projectileEl.components['enemy-projectile']);
      projectileEl.components['enemy-projectile'].startChasing(this.el.object3D);
      window.audioManager.playSound('shardsJuttingAudio');
    }
  },

  setAttackEnabled(newValue = true) {
    this.attackEnabled = newValue;
    if (this.attackEnabled) {
      this.moveToPlayer();
    }
  },

  animationFinished(action, direction) {
    // console.log('animationFinished() clip.name:', action.detail.action._clip.name, ', action:', action, ', direction:', direction);
    if (action.detail.action._clip.name === this.attackAnimationClip) { // Finished attacking
      if (window.gameManager.getCurrentLevelData().monsterActionDelay > 0) this.setAnimation(this.idleAnimationClip, true);
      if (this.attackEnabled) {
        if (this.nextActionTimer) clearTimeout(this.nextActionTimer); // Stop setTimeout
        this.nextActionTimer = setTimeout(() => {
          this.checkNextMove();
        }, window.gameManager.getCurrentLevelData().monsterActionDelay);
      }
    }
    else if (action.detail.action._clip.name === this.introAnimationClip) {
      this.startAI();
    }
  },

  checkNextMove() {
    const rand = Math.random();
    if (
      rand < 0.75
      || this.consecutiveAttacks >= 2
      || this.el.sceneEl.components.pool__enemy_projectile.availableEls.length === 0 // There are no projectiles in the pool yet
    ) this.moveToPlayer();
    else this.movementComplete(); // Try attack
  },

  damage() {
    // Play damage animation? Additive?
    if (this.damageTween) this.damageTween.kill();
    // window.audioManager.playEnemyDamageSound();
    this.damageTween = gsap.fromTo(this.matRef.emissive,
      {
        r: 0,
      },
      {
        duration: 0.07,
        r: 0.5,
        ease: 'sine.out',
        yoyo: true,
        repeat: 1,
      },
    );
  },

  defeated() {
    this.setAttackEnabled(false);
    if (this.moveTween) this.moveTween.kill();
    this.setAnimation(this.defeatedAnimationClip, false, 1, true);
    window.audioManager.playSound('monsterRoarAudio');
    this.el.components['look-at'].setTrackingEnabled(false);
    setTimeout(() => {
      this.showHideMonster(false);
    }, 1000);
  },
};

export { enemyMonsterComponent };
