/* eslint-disable no-else-return */
/* import System, {
  Alpha,
  Color,
  Emitter,
  Life,
  Mass,
  PointZone,
  Position,
  RadialVelocity,
  Radius,
  Rate,
  Scale,
  Span,
  SpriteRenderer,
  Vector3D,
  Velocity
} from 'three-nebula'; */
// import Nebula, { SpriteRenderer } from 'three-nebula';
import * as THREE from 'three';
import ParticleSystem, {
  Alpha,
  Body,
  Color,
  CrossZone,
  ease,
  Emitter,
  Force,
  Life,
  Mass,
  RadialVelocity,
  Radius,
  RandomDrift,
  Rate,
  Scale,
  ScreenZone,
  Span,
  SpriteRenderer,
  Vector3D,
} from 'three-nebula';
import dot from './dot.png';
import particleSmokeTexture from './aframe-particle-system-component/particle_smoke_single_64.png';
import particleShardsTexture from './aframe-particle-system-component/particle_shards_128_2.png';
import particlejson from './particle-data2.json';
import Utils from './utils.js';

const particleManagerComponent = {
  init() {
    window.particleManager = this;
    this.textureLoader = new THREE.TextureLoader();
    this.aCamera = this.el.sceneEl.querySelector('#camera');
    this.threeCamera = this.aCamera.getObject3D('camera');
    this.particleSystems = [];
    this.textureCacheReferences = {};

    this.subscribeToEvents();
  },

  subscribeToEvents() {
    this.el.sceneEl.addEventListener('renderstart', this.renderTargetLoaded.bind(this));
  },

  tick(time, deltaTime) {
    for (let i = 0; i < this.particleSystems.length; i++) {
      this.particleSystems[i].update(deltaTime * 0.001);
    }
  },

  renderTargetLoaded(a, b) {
  },

  createParticleSystem_smoke(parent) {
    const system = new ParticleSystem();

    this.emitterA = new Emitter();
    this.emitterA
      .setRate(new Rate(new Span(1, 1), new Span(0.3, 0.3)))
      .setInitializers([
        new Mass(1),
        new Life(1),
        new Body(this.createSprite(particleSmokeTexture)),
        new Radius(0.15),
        new RadialVelocity(1, new Vector3D(0, 0, -1), 1),
      ])
      .setBehaviours([
        new Alpha(1, 0, undefined, ease.easeInCubic),
        // new Color('#FFFFFF', '#FFFFFF'),
        // new RandomDrift(0.004, 0.004, 0.004, 0.1),
        new Scale(0.7, 1.6, undefined, ease.easeOutCubic),
        // new CrossZone(new ScreenZone(this.threeCamera, this.el.sceneEl.renderer), 'dead'),
        // new Force(0, -0.01, 0),
      ])
      .emit();

    system.addEmitter(this.emitterA);

    const nebulaSpriteRenderer = new SpriteRenderer(parent, THREE);
    const particleSystem = system.addRenderer(nebulaSpriteRenderer);
    this.particleSystems.push(particleSystem);
    return particleSystem;
  },

  createParticleSystem_shards(parent) {
    const system = new ParticleSystem();

    this.emitterA = new Emitter();
    this.emitterA
      .setRate(new Rate(1, 10))
      .setInitializers([
        new Mass(1),
        new Life(0.8),
        new Body(this.createSprite(particleShardsTexture)),
        new Radius(0.21),
        new RadialVelocity(0.5, new Vector3D(0, 1, -0.4), 1),
      ])
      .setBehaviours([
        new Alpha(1, 0, undefined, ease.easeInQuart),
        // new Color('#FFFFFF', '#0029FF'),
        // new RandomDrift(0.1, 0.1, 0.1, 0.4),
        new Scale(0.7, 2.0),
        // new CrossZone(new ScreenZone(this.threeCamera, this.el.sceneEl.renderer), 'dead'),
        new Force(0, -0.007, 0),
      ]);
    // this.emitterA.emit(); // Don't start emitting automatically

    // To use this particles with one shot:
    /* this.shardsParticleSystem = window.particleManager.createParticleSystem_shards(this.el.object3D);
    this.shardsParticleSystem.canUpdate = true;
    window.particleManager.setParticleEmit(this.shardsParticleSystem, true);
    setTimeout(() => {
      window.particleManager.setParticleEmit(this.shardsParticleSystem, false);
    }, 100); */
    //

    system.addEmitter(this.emitterA);

    const nebulaSpriteRenderer = new SpriteRenderer(parent, THREE);
    const particleSystem = system.addRenderer(nebulaSpriteRenderer);
    this.particleSystems.push(particleSystem);
    return particleSystem;
  },

  createSprite(textureAsset) {
    if (!this.textureCacheReferences[textureAsset]) {
      const spriteTexture = this.textureLoader.load(textureAsset);
      spriteTexture.encoding = 3001;
      spriteTexture.needsUpdate = true;
      const material = new THREE.SpriteMaterial({
        map: spriteTexture,
        color: 0xffffff,
        blending: THREE.NormalBlending,
        fog: false,
      });
      const sprite = new THREE.Sprite(material);
      this.textureCacheReferences[textureAsset] = sprite;
      return sprite;
    }
    else {
      return this.textureCacheReferences[textureAsset];
    }
  },

  setParticleEmit(particleSystem, newValue) {
    for (let i = 0; i < particleSystem.emitters.length; i++) {
      if (particleSystem.emitters[i].originalRateNumPanA === undefined) particleSystem.emitters[i].originalRateNumPanA = particleSystem.emitters[i].rate.numPan.a;
      if (particleSystem.emitters[i].originalRateNumPanB === undefined) particleSystem.emitters[i].originalRateNumPanB = particleSystem.emitters[i].rate.numPan.b;
      if (newValue) {
        particleSystem.emitters[i].rate.numPan.a = particleSystem.emitters[i].originalRateNumPanA;
        particleSystem.emitters[i].rate.numPan.b = particleSystem.emitters[i].originalRateNumPanB;
        particleSystem.emitters[i].rate.init();
        particleSystem.emitters[i].emit();
        particleSystem.emitters[i].rate.nextTime = 0; // Ensure the first emit is as soon as possible
      }
      else {
        particleSystem.emitters[i].rate.numPan.a = 0;
        particleSystem.emitters[i].rate.numPan.b = 0;
      }
    }
  },
};

export { particleManagerComponent };
