import gsap from 'gsap';

/* eslint-disable no-else-return */
const { debug } = AFRAME.utils;
const { coordinates } = AFRAME.utils;

const warn = debug('components:look-at:warn');
const isCoordinates = coordinates.isCoordinates || coordinates.isCoordinate;

delete AFRAME.components['look-at'];

const lookAtComponent = {
  schema: {
    enabled: { default: true },
    lerpFactor: { default: 0 },
    forceY: { default: null },
    yOffset: { default: 0 },
    target: {
      default: '',

      parse(value) {
        // A static position to look at.
        if (isCoordinates(value) || typeof value === 'object') {
          return coordinates.parse(value);
        }
        // A selector to a target entity.
        return value;
      },

      stringify(data) {
        if (typeof data === 'object') {
          window.console.log('lookAtComponent typeof data === object:');
          return coordinates.stringify(data);
        }
        return data;
      },
    },
  },

  init() {
    this.parsedForceY = parseFloat(this.data.forceY, 10) || 0;
    this.parsedLerpFactor = parseFloat(this.data.lerpFactor, 10) || 0;

    this.worldQuaternion = new THREE.Quaternion();
    this.targetQuaternion = new THREE.Quaternion();
    this.tempMatrix = new THREE.Matrix4();
    this.worldPosition = new THREE.Vector3();
    this.targetWorldPosition = new THREE.Vector3();
  },

  update() {
    if (!this.data.enabled || (window.gameManager && window.gameManager.isPaused)) return;
    this.processTracking();
  },

  processTracking() {
    // No longer looking at anything (i.e., look-at="").
    if (!this.data.target || (typeof this.data.target === 'object' && !Object.keys(this.data.target).length)) {
      return this.remove();
    }

    // Look at a position.
    if (typeof this.data.target === 'object') {
      return this.el.object3D.lookAt(new THREE.Vector3(
        this.data.target.x,
        this.data.forceY !== null ? this.data.forceY : this.data.target.y,
        this.data.target.z,
      ));
    }

    // Assume target is a string.
    // Query for the element, grab its object3D, then register a behavior on the scene to
    // track the target on every tick.
    const targetEl = this.el.sceneEl.querySelector(this.data.target);
    if (!targetEl) {
      warn(`"${this.data.target}" does not point to a valid entity to look-at`);
      return null;
    }
    if (!targetEl.hasLoaded) {
      return targetEl.addEventListener('loaded', () => {
        this.setTrackingTarget(targetEl);
      });
    }
    return this.setTrackingTarget(targetEl);
  },

  tick(time, deltaTime) {
    if (!this.data.enabled || (window.gameManager && window.gameManager.isPaused)) return;
    // /////////// return (t) => {
    // Track target object position. Depends on parent object keeping global transforms up
    // to state with updateMatrixWorld(). In practice, this is handled by the renderer.

    if (this.target3D) {
      this.el.object3D.getWorldPosition(this.worldPosition);
      if (this.target3D.isObject3D) {
        this.target3D.getWorldPosition(this.targetWorldPosition);
      }
      else {
        this.targetWorldPosition.copy(this.target3D);
      }
      if (this.data.yOffset) {
        this.targetWorldPosition.y += this.data.yOffset;
      }
      // console.log('this.targetWorldPosition:', this.targetWorldPosition);
      /* if (this.el.getObject3D('camera')) {
        // Flip the vector to -z, looking away from target for camera entities. When using
        // lookat from THREE camera objects, this is applied for you, but since the camera is
        // nested into a Object3D, we need to apply this manually.
        // vector.subVectors(object3D.position, vec3).add(object3D.position);
      } else {
        this.vector = this.targetWorldPosition;
      } */
      if (this.data.forceY !== null && this.parsedForceY !== null) {
        this.targetWorldPosition.y = this.parsedForceY;
      }
      if (!this.parsedLerpFactor) {
        // Instant rotation
        this.el.object3D.lookAt(this.targetWorldPosition);
        this.worldQuaternion.copy(this.el.object3D.quaternion);
      }
      else {
        // Smooth rotation
        // Get the current quaternion
        this.el.object3D.quaternion.copy(this.worldQuaternion);

        // Calculate the target quaternion
        this.el.object3D.lookAt(this.targetWorldPosition);
        this.targetQuaternion.copy(this.el.object3D.quaternion);

        // Reset the quaternion to the current quaternion
        this.el.object3D.quaternion.copy(this.worldQuaternion);

        // Interpolate between the current quaternion and the target quaternion
        this.worldQuaternion.slerp(this.targetQuaternion, this.parsedLerpFactor * deltaTime);

        // Apply the interpolated quaternion to the object
        this.el.object3D.quaternion.copy(this.worldQuaternion);
      }
    }
    // /////////// }
  },

  setTrackingTarget(newTarget) {
    this.target3D = newTarget.object3D || newTarget;
  },

  setTrackingEnabled(newValue = true) {
    this.data.enabled = newValue;
  },

  setLerpFactor(newValue = 1, duration = 1.5) {
    if (this.setLetpFactorTween) this.setLetpFactorTween.kill();
    if (duration) {
      this.setLetpFactorTween = gsap.to(this.data, {
        lerpFactor: newValue,
        duration: duration,
        ease: 'none',
        onUpdate: () => {
          this.parsedLerpFactor = this.data.lerpFactor;
        },
      });
    }
    else {
      this.data.lerpFactor = newValue;
      this.parsedLerpFactor = newValue;
    }
  },

  incrementLerpFactor(amount = 0.001) {
    if (this.data.lerpFactor < 0.2) this.setLerpFactor(this.data.lerpFactor + amount);
  },

  setYOffset(newValue = 0) {
    this.data.yOffset = newValue;
  },
};

export { lookAtComponent };
