const dirtyWords = [
  '4r5e', '5h1t', '5h1tf@c3', '5h1tf@ce', '5h1th34d', '5h1thead', '5hit', 'CP', 'FDAsucks', 'FDAsux', 'Necrophilia', 'Trump', 'a$$', 'a$$fucker', 'a$s', 'a55', 'a55fucker', 'a55hole', 'a_s_s', 'aeolus',
  'ahole', 'alaskan pipeline', 'anal', 'analprobe', 'anilingus', 'anus', 'apeshit', 'ar5e', 'areola', 'areole', 'arian', 'arrse', 'arse', 'arsehole', 'arses', 'aryan', 'as$', 'ass', 'ass hole', 'ass-fucker',
  'assbang', 'assbanged', 'assbangs', 'asses', 'assfcker', 'assfuck', 'assfucker', 'assfukka', 'assh0le', 'asshat', 'assho1e', 'asshole', 'assholes', 'assmaster', 'assmunch', 'asswhole',
  'asswipe', 'asswipes', 'autism', 'autistic', 'auto erotic', 'autoerotic', 'azazel', 'azz', 'b!tch', 'b00bs', 'b0mb', 'b17ch', 'b1tch', 'babe', 'babeland', 'babes', 'baby batter', 'baby juice',
  'ball gag', 'ball gravy', 'ball kicking', 'ball licking', 'ball sack', 'ball sucking', 'ballbag', 'balls', 'ballsack', 'bang', 'bangbros', 'banger', 'bareback', 'barely legal',
  'barenaked', 'barf', 'bastard', 'bastardo', 'bastards', 'bastinado', 'bawdy', 'bbw', 'bdsm', 'beaner', 'beaners', 'beardedclam', 'beastial', 'beastiality', 'beatch', 'beater', 'beaver', 'beaver cleaver',
  'beaver lips', 'beer', 'beeyotch', 'bellend', 'beotch', 'bestial', 'bestiality', 'bi+ch', 'biatch', 'biden', 'bidensucks', 'big black', 'big breasts', 'big knockers', 'big tits', 'bigtits',
  'bimbo', 'bimbos', 'birdlock', 'bitch', 'bitchboy', 'bitched', 'bitcher', 'bitchers', 'bitches', 'bitchin', 'bitching', 'bitchy', 'black cock', 'blonde action', 'blonde on blonde action',
  'bloody', 'blow', 'blow job', 'blow your load', 'blowjob', 'blowjobs', 'blue waffle', 'blumpkin', 'bod', 'bodily', 'boink', 'boiolas', 'bollock', 'bollocks', 'bollok', 'bomb', 'bombed', 'bondage',
  'bone', 'boned', 'boner', 'boners', 'bong', 'boob', 'boobies', 'boobs', 'booby', 'booger', 'bookie', 'booobs', 'boooobs', 'booooobs', 'booooooobs', 'bootee', 'bootie', 'booty', 'booty call', 'booze',
  'boozer', 'boozy', 'bosom', 'bosomy', 'bowel', 'bowels', 'bra', 'brassiere', 'breast', 'breasts', 'brown showers', 'brunette action', 'buceta', 'bugger', 'bukkake', 'bull shit', 'bulldyke',
  'bullet vibe', 'bullshit', 'bullshits', 'bullshitted', 'bullturds', 'bum', 'bung', 'bung hole', 'bunghole', 'busty', 'butt', 'butt fuck', 'buttcheeks', 'buttfuck', 'buttfucker', 'butthole',
  'buttmuch', 'buttplug', 'butts', 'c-0-c-k', 'c-o-c-k', 'c-u-n-t', 'c.0.c.k', 'c.o.c.k.', 'c.u.n.t', 'c0ck', 'c0ck5uck3r', 'c0ck5ucka', 'c0ckf@ce', 'c0ckh3ad', 'c0cksck3r', 'c0cksuck3r',
  'c0cksucka', 'c0cksucker', 'caca', 'cahone', 'camel toe', 'cameltoe', 'camgirl', 'camslut', 'camwhore', 'carpet muncher', 'carpetmuncher', 'cawk', 'cervix', 'ch1nk', 'chinc', 'chincs',
  'chink', 'chocolate rosebuds', 'chode', 'chodes', 'cipa', 'circlejerk', 'cl1t', 'cleveland steamer', 'climax', 'clit', 'clitoris', 'clitorus', 'clits', 'clitty', 'clover clamps', 'clusterfuck',
  'cnut', 'cocain', 'cocaine', 'cock', 'cock sucker', 'cock-sucker', 'cockblock', 'cockface', 'cockhead', 'cockholster', 'cockknocker', 'cockmunch', 'cockmuncher', 'cocks', 'cocksmoker',
  'cocksuck', 'cocksucked', 'cocksucker', 'cocksucking', 'cocksucks', 'cocksuka', 'cocksukka', 'coital', 'cok', 'cokmuncher', 'coksucka', 'commie', 'condom', 'conservative', 'coon',
  'coons', 'coprolagnia', 'coprophilia', 'corksucker', 'cornhole', 'cox', 'crabs', 'crack', 'cracker', 'crackwhore', 'crap', 'crappy', 'creampie', 'cub', 'cum', 'cummer', 'cummin', 'cumming', 'cums',
  'cumshot', 'cumshots', 'cumslut', 'cumstain', 'cunilingus', 'cunillingus', 'cunnilingus', 'cunny', 'cunt', 'cuntface', 'cunthunter', 'cuntlick', 'cuntlicker', 'cuntlicking', 'cunts',
  'cutmyself', 'cyalis', 'cyberfuc', 'cyberfuck', 'cyberfucked', 'cyberfucker', 'cyberfuckers', 'cyberfucking', 'd0ng', 'd0uch3', 'd0uche', 'd1ck', 'd1ckf@c3', 'd1ckf@ce', 'd1ckh3ad',
  'd1ckhead', 'd1ld0', 'd1ldo', 'dago', 'dagos', 'dammit', 'damn', 'damned', 'damnit', 'darkie', 'date rape', 'daterape', 'dawgie-style', 'deep throat', 'deepthroat', 'deeznutz', 'dendrophilia',
  'depressed', 'depression', 'depressions', 'dick', 'dick-ish', 'dickbag', 'dickdipper', 'dickface', 'dickflipper', 'dickhead', 'dickheads', 'dickish', 'dickripper', 'dicksipper',
  'dickweed', 'dickwhipper', 'dickzipper', 'diddle', 'die', 'dike', 'dildo', 'dildos', 'diligaf', 'dillweed', 'dimwit', 'dingle', 'dingleberries', 'dingleberry', 'dink', 'dinks', 'dipship',
  'dirsa', 'dirty pillows', 'dirty sanchez', 'dlck', 'dog style', 'dog-fucker', 'doggie style', 'doggie-style', 'doggiestyle', 'doggin', 'dogging', 'doggy style', 'doggy-style',
  'doggystyle', 'dolcett', 'domination', 'dominatrix', 'dommes', 'dong', 'donkey punch', 'donkeyribber', 'doofus', 'doosh', 'dopey', 'double dong', 'double penetration', 'douch3',
  'douche', 'douchebag', 'douchebags', 'douchey', 'dp action', 'drunk', 'dry hump', 'duche', 'dumass', 'dumb', 'dumbass', 'dumbasses', 'dummy', 'dvda', 'dyke', 'dykes', 'eat my ass', 'ecchi', 'ejaculate',
  'ejaculated', 'ejaculates', 'ejaculating', 'ejaculatings', 'ejaculation', 'ejakulate', 'enlargement', 'erect', 'erection', 'erotic', 'erotism', 'escort', 'essohbee', 'eunuch',
  'extacy', 'extasy', 'f u c k', 'f u c k e r', 'f-u-c-k', 'f.u.c.k', 'f4nny', 'f@gg0t', 'f@gg0ts', 'f@gg1t', 'f@gg1ts', 'f@ggit', 'f@ggits', 'f@gs', 'f@gz', 'f_u_c_k', 'fack', 'fag', 'fagg', 'fagg0t',
  'fagged', 'fagging', 'faggit', 'faggitt', 'faggot', 'faggs', 'fagot', 'fagots', 'fags', 'faig', 'faigt', 'fanny', 'fannybandit', 'fannyflaps', 'fannyfucker', 'fanyy', 'farright', 'fart', 'fartknocker',
  'fat', 'fatass', 'fcuk', 'fcuker', 'fcuking', 'fecal', 'feces', 'feck', 'fecker', 'felch', 'felcher', 'felching', 'fellate', 'fellatio', 'feltch', 'feltcher', 'female squirting', 'femdom', 'figging',
  'fingerbang', 'fingerfuck', 'fingerfucked', 'fingerfucker', 'fingerfuckers', 'fingerfucking', 'fingerfucks', 'fingering', 'fisted', 'fistfuck', 'fistfucked', 'fistfucker',
  'fistfuckers', 'fistfucking', 'fistfuckings', 'fistfucks', 'fisting', 'fisty', 'flange', 'floozy', 'foad', 'fondle', 'foobar', 'fook', 'fooker', 'foot fetish', 'footjob', 'foreskin', 'freex',
  'frigg', 'frigga', 'frotting', 'fubar', 'fuc#3r', 'fuc#er', 'fuc3r', 'fucer', 'fuck', 'fuck buttons', 'fuck-tard', 'fucka', 'fuckass', 'fuckbiden', 'fucked', 'fucker', 'fuckers', 'fuckface', 'fuckhead',
  'fuckheads', 'fuckin', 'fucking', 'fuckings', 'fuckingshitmotherfucker', 'fucklife', 'fuckme', 'fucknugget', 'fucknut', 'fuckoff', 'fucks', 'fucktard', 'fucktards', 'fuckthefda',
  'fucktrc', 'fucktrump', 'fuckup', 'fuckwad', 'fuckwhit', 'fuckwit', 'fudge packer', 'fudgepacker', 'fuk', 'fuker', 'fukker', 'fukkin', 'fuks', 'fukwhit', 'fukwit', 'furfag', 'futanari', 'fux',
  'fux0r', 'fux0rs', 'fux0rz', 'fuxor', 'fuxors', 'fuxorz', 'fvck', 'fxck', 'g-spot', 'gae', 'gai', 'gang bang', 'gangbang', 'gangbanged', 'gangbangs', 'ganja', 'gay', 'gay sex', 'gayboy', 'gaylord', 'gays',
  'gaysex', 'gaza', 'genitals', 'gey', 'gfy', 'ghay', 'ghey', 'giant cock', 'gigolo', 'girl on', 'girl on top', 'girls gone wild', 'glans', 'goatcx', 'goatse', 'god damn', 'god-dam', 'god-damned',
  'godamn', 'godamnit', 'goddam', 'goddammit', 'goddamn', 'goddamned', 'gokkun', 'golden shower', 'goldenshower', 'gonad', 'gonads', 'goo girl', 'goodpoop', 'gook', 'gooks', 'goregasm',
  'gringo', 'grope', 'group sex', 'gspot', 'gtfo', 'guido', 'guro', 'h0m0', 'h0mo', 'hand job', 'handjob', 'hard on', 'hardcoresex', 'hawktua', 'he11', 'headass', 'hebe', 'heeb', 'hell', 'hemp', 'hentai', 'heroin',
  'herp', 'herpes', 'herpy', 'hitler', 'hiv', 'hoar', 'hoare', 'hobag', 'hoe', 'hoer', 'hoes', 'hom0', 'homey', 'homo', 'homoerotic', 'homoey', 'honkey', 'honky', 'hooch', 'hookah', 'hooker', 'hoor', 'hootch', 'hooter',
  'hooters', 'hore', 'horniest', 'horny', 'hot carl', 'hot chick', 'hotsex', 'how to kill', 'how to murder', 'huge fat', 'hump', 'humped', 'humping', 'hussy', 'hymen', 'inbred', 'incest', 'injun',
  'intercourse', 'isra3l', 'israel', 'ivape', 'j3rk0ff', 'jack off', 'jack-off', 'jackass', 'jackhole', 'jackoff', 'jail bait', 'jailbait', 'jap', 'japs', 'jelly donut', 'jerk', 'jerk off',
  'jerk-off', 'jerk0ff', 'jerked', 'jerkoff', 'jew', 'jews', 'jewz', 'jigaboo', 'jiggaboo', 'jiggerboo', 'jism', 'jiz', 'jizm', 'jizz', 'jizzed', 'juggs', 'junkie', 'junky', 'kawk', 'kike', 'kikes', 'kill', 'killmyself',
  'kinbaku', 'kinkster', 'kinky', 'kkk', 'klan', 'knobbing', 'knobead', 'knobed', 'knobend', 'knobhead', 'knobjocky', 'knobjokey', 'kock', 'kondum', 'kondums', 'kooch', 'kooches', 'kootch', 'kraut',
  'kum', 'kummer', 'kumming', 'kums', 'kunilingus', 'kunt', 'kyke', 'l3i+ch', 'l3itch', 'labia', 'leather restraint', 'leather straight jacket', 'lech', 'lemon party', 'leper', 'lesbians',
  'lesbo', 'lesbos', 'lez', 'lezbian', 'lezbians', 'lezbo', 'lezbos', 'lezzie', 'lezzies', 'lezzy', 'liberal', 'libtard', 'lmao', 'lmfao', 'loin', 'loins', 'loli', 'lolicon', 'lolita', 'lovemaking', 'lovetovape',
  'lovevape', 'lube', 'lust', 'lusting', 'lusty', 'luvtovape', 'm-fucking', 'm0f0', 'm0fo', 'm45terbate', 'ma5terb8', 'ma5terbate', 'maga', 'make me come', 'male squirting', 'mams', 'masochist',
  'massa', 'master-bate', 'masterb8', 'masterbat*', 'masterbat3', 'masterbate', 'masterbating', 'masterbation', 'masterbations', 'masturbate', 'masturbating', 'masturbation',
  'maxi', 'menage a trois', 'menses', 'menstruate', 'menstruation', 'meth', 'milf', 'missionary position', 'mo-fo', 'mof0', 'mofo', 'molest', 'mongoloid', 'moolie', 'moron', 'mothafuck',
  'mothafucka', 'mothafuckas', 'mothafuckaz', 'mothafucked', 'mothafucker', 'mothafuckers', 'mothafuckin', 'mothafucking', 'mothafuckings', 'mothafucks', 'motherfuck',
  'motherfucka', 'motherfucked', 'motherfucker', 'motherfuckers', 'motherfuckin', 'motherfucking', 'motherfuckings', 'motherfuckka', 'motherfucks', 'mound of venus',
  'mr hands', 'mtherfucker', 'mthrfucker', 'mthrfucking', 'muff', 'muff diver', 'muffdiver', 'muffdiving', 'murder', 'muthafecker', 'muthafuckaz', 'muthafucker', 'muthafuckker',
  'mutherfucker', 'mutherfucking', 'muthrfucking', 'n1gga', 'n1gger', 'nad', 'nads', 'naked', 'nambla', 'napalm', 'nappy', 'nawashi', 'nazi', 'nazism', 'negro', 'neonazi', 'nig nog', 'nigg3r', 'nigg4h',
  'nigga', 'niggah', 'niggas', 'niggaz', 'nigger', 'niggers', 'niggle', 'niglet', 'nimphomania', 'nimrod', 'ninny', 'nipple', 'nipples', 'nob', 'nob jokey', 'nobhead', 'nobjocky', 'nobjokey', 'nooky',
  'nsfw images', 'nude', 'nudity', 'numbnuts', 'nutsack', 'nympho', 'nymphomania', 'octopussy', 'omorashi', 'one cup two girls', 'one guy one jar', 'opiate', 'opium', 'oral', 'orally',
  'organ', 'orgasim', 'orgasims', 'orgasm', 'orgasmic', 'orgasms', 'orgies', 'orgy', 'ovary', 'ovum', 'ovums', 'p.u.s.s.y.', 'p00p', 'p0rn', 'p0rn0', 'p0rn0s', 'p0rno', 'p3n1s', 'p3ni5', 'paddy', 'paedophile',
  'paki', 'pal3s1n3', 'pal3stine', 'palest1ne', 'palestine', 'pantie', 'panties', 'panty', 'pastie', 'pasty', 'pawn', 'pcp', 'pecker', 'ped0', 'ped0phelia', 'ped0phile', 'pedo', 'pedobear', 'pedofile',
  'pedophelia', 'pedophile', 'pedophilia', 'pedophiliac', 'pee', 'peepee', 'pegging', 'pen!s', 'pen1s', 'penetrate', 'penetration', 'peni$', 'peni5', 'penial', 'penile', 'penis', 'penisfucker',
  'perversion', 'peyote', 'phalli', 'phallic', 'phone sex', 'phonesex', 'phuck', 'phuk', 'phuked', 'phuking', 'phukked', 'phukking', 'phuks', 'phuq', 'piece of shit', 'pigfucker', 'pillowbiter',
  'pimp', 'pimpis', 'pinko', 'piss', 'piss pig', 'piss-off', 'pissed', 'pisser', 'pissers', 'pisses', 'pissflaps', 'pissin', 'pissing', 'pissoff', 'pisspig', 'playboy', 'pleasure chest', 'pms',
  'polack', 'pole smoker', 'pollock', 'ponyplay', 'poof', 'poon', 'poontang', 'poop', 'poop chute', 'poopchute', 'porn', 'porno', 'pornography', 'pornos', 'pot', 'potty', 'pr0n', 'pr0n0', 'pr0n0s',
  'prick', 'pricks', 'prig', 'prince albert piercing', 'pron', 'prostitute', 'prude', 'pthc', 'pube', 'pubes', 'pubic', 'pubis', 'punany', 'punkass', 'punky', 'puss', 'pusse', 'pussi', 'pussies', 'pussy',
  'pussypounder', 'pussys', 'puto', 'queaf', 'queef', 'queer', 'queero', 'queers', 'quicky', 'quim', 'r-tard', 'racy', 'raghead', 'raging boner', 'rape', 'raped', 'raper', 'raping', 'rapist', 'raunch',
  'rectal', 'rectum', 'rectus', 'redtube', 'reefer', 'reetard', 'reich', 'retard', 'retarded', 'reverse cowgirl', 'revue', 'rimjaw', 'rimjob', 'rimming', 'ritard', 'rosy palm', 'rtard', 'rum', 'rump',
  'rumprammer', 'ruski', 'rusty trombone', 's hit', 's&m', 's-h-1-t', 's-h-i-t', 's-o-b', 's.h.i.t.', 's.o.b.', 's0b', 's_h_i_t', 'sadism', 'sadist', 'santorum', 'scag', 'scantily', 'scat', 'schizo',
  'schlong', 'scissoring', 'screw', 'screwed', 'screwing', 'scroat', 'scrog', 'scrot', 'scrote', 'scrotum', 'scrud', 'scum', 'seaman', 'seamen', 'seduce', 'semen', 'sex', 'sexo', 'sexual', 'sexy', 'sh!+', 'sh!t',
  'sh00t', 'sh00ting', 'sh1t', 'sh1tf@c3', 'sh1tf@ce', 'sh1th34d', 'sh1th3ad', 'sh1the4d', 'sh1the@d', 'shag', 'shagger', 'shaggin', 'shagging', 'shamedame', 'shaved beaver', 'shaved pussy',
  'shemale', 'shi+', 'shibari', 'shit', 'shitblimp', 'shitdick', 'shite', 'shiteater', 'shited', 'shitey', 'shitface', 'shitfuck', 'shitfull', 'shithead', 'shithole', 'shithouse', 'shiting',
  'shitings', 'shits', 'shitt', 'shitted', 'shitter', 'shitters', 'shitting', 'shittings', 'shitty', 'shiz', 'shoot', 'shooting', 'shota', 'shrimping', 'sissy', 'skag', 'skank', 'skeet', 'slanteye',
  'slave', 'sleaze', 'sleazy', 'slut', 'slutdumper', 'slutkiss', 'sluts', 'smegma', 'smut', 'smutty', 'snatch', 'sniper', 'snowballing', 'snuff', 'sodom', 'sodomize', 'sodomy', 'son-of-a-bitch',
  'souse', 'soused', 'spac', 'sperm', 'spic', 'spick', 'spik', 'spiks', 'splooge', 'splooge moose', 'spooge', 'spread legs', 'spunk', 'steamy', 'stfu', 'stiffy', 'stoned', 'strap on', 'strapon', 'strappado',
  'strip', 'strip club', 'stroke', 'stupid', 'style doggy', 'suck', 'sucked', 'sucking', 'sucks', 'suicide girls', 'sultry women', 'sumofabiatch', 'swastika', 'swinger', 't1t', 't1tt1e5',
  't1tties', 'tainted love', 'tampon', 'tard', 'taste my', 'tawdry', 'tea bagging', 'teabagging', 'teat', 'teets', 'teez', 'terd', 'terrorism', 'terrorist', 'teste', 'testee', 'testes', 'testical',
  'testicle', 'testis', 'threesome', 'throating', 'thrust', 'thug', 'tied up', 'tight white', 'tinkle', 'tit', 'titfuck', 'titi', 'tits', 'titt', 'tittie5', 'tittiefucker', 'titties', 'titty', 'tittyfuck',
  'tittyfucker', 'tittywank', 'titwank', 'toke', 'tongue in a', 'toots', 'topless', 'tosser', 'towelhead', 'tramp', 'tranny', 'transsexual', 'trashy', 'trcsucks', 'trcsux', 'tribadism',
  'trumpsucks', 'tub girl', 'tubgirl', 'turd', 'tush', 'tushy', 'tw4t', 'twat', 'twathead', 'twats', 'twatty', 'twink', 'two girls one cup', 'twunt', 'twunter', 'ugly', 'undies', 'undressing', 'unwed',
  'upskirt', 'urethra play', 'urinal', 'urine', 'urophilia', 'uterus', 'uzi', 'v14gra', 'v1gra', 'vag', 'vagina', 'valium', 'vape', 'vapes', 'vaping', 'venus mound', 'viagra', 'vibrator', 'violet wand',
  'virgin', 'vixen', 'vodka', 'vomit', 'vorarephilia', 'voyeur', 'vulgar', 'vulva', 'w00se', 'wad', 'wang', 'wank', 'wanker', 'wanky', 'wazoo', 'weaboo', 'wedgie', 'weeb', 'weed', 'weenie', 'weewee', 'weiner',
  'weirdo', 'wench', 'wet dream', 'wetback', 'wh0re', 'wh0reface', 'white power', 'whitey', 'whiz', 'whoar', 'whoralicious', 'whore', 'whorealicious', 'whored', 'whoreface', 'whorehopper',
  'whorehouse', 'whores', 'whoring', 'wigger', 'willies', 'willy', 'womb', 'woody', 'wop', 'wrapping men', 'wrinkled starfish', 'wtf', 'x-rated', 'xxx', 'yaoi', 'yeasty', 'yellow showers', 'yiffy',
  'yobbo', 'zoophile', 'zoophilia',
];

// Duplicate words removing spaces
for (let i = 0; i < dirtyWords.length; i++) {
  if (dirtyWords[i].indexOf(' ') > -1) {
    const noSpaceWord = dirtyWords[i].replace(/\s/g, '');
    if (!dirtyWords.includes(noSpaceWord)) dirtyWords.push(noSpaceWord);
  }
}

export default class NeoGame {
  static checkForProfanity(wordToCheck) {
    wordToCheck = wordToCheck.toLowerCase();
    for (let i = 0; i < dirtyWords.length; i++) {
      if (wordToCheck.indexOf(dirtyWords[i]) > -1) {
        return true;
      }
    }
    return false;
  }
}
