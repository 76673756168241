import gsap from 'gsap';
import Config from './config.js';

// Component that places models at cursor location when screen is tapped
const tapPlaceCursorComponent = {
  schema: {
    name: { default: 'tap' },
  },

  init() {
    window.tapPlaceCursorRef = this;
    this.el.tapPlaceCursorRef = this;
    this.isEnabled = false;
    this.raycaster = new THREE.Raycaster();

    setTimeout(() => {
      this.getElements();
    }, 500);

    // 2D coordinates of the raycast origin, in normalized device coordinates (NDC)---X and Y
    // components should be between -1 and 1.  Here we want the cursor in the center of the screen.
    this.rayOrigin = new THREE.Vector2(0, 0);

    this.cursorLocation = new THREE.Vector3(0, 0, 0);

    this.el.object3D.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        child.material.envMap = null;
        child.material.envMapIntensity = 0;
        child.material.needsUpdate = true;
      }
    });
  },

  getElements() {
    this.aCamera = document.getElementById('camera');
    this.threeCamera = this.aCamera.components.camera.camera;
    this.ground = document.getElementById('ground');
    this.originMarker = document.getElementById('originMarker');
    this.originMarkerText = document.getElementById('originMarkerText');

    this.getElementsCompleted = true;
  },

  startGame() {
    this.isEnabled = true;
    this.el.sceneEl.addEventListener('click', () => {
      if (!this.placed) {
        window.audioManager.playButtonSound();
        this.placeScene();
      }
    });
  },

  placeScene() {
    if (this.placed/* || (!window.gameManager.trackingInitialized && !window.isDesktop)*/) return;
    console.warn('tapPlaceCursorComponent placeScene()');
    this.placed = true;
    this.ground.classList.remove('raycastInteractable');
    gsap.to(this.el.object3D.scale,
      {
        x: 0.01,
        y: 0.01,
        z: 0.01,
        duration: 0.3,
        ease: 'power1.in',
        onComplete: () => {
          this.el.setAttribute('visible', false);
        },
      },
    );
    window.gameManager.placeSceneWithoutCard();
  },

  restartScene() {
    window.console.log('restartScene() trackingInitialized:', window.gameManager.trackingInitialized);
    this.placed = false;
    this.originMarker.object3D.scale.set(Config.markerScale, Config.markerScale, Config.markerScale);
    this.originMarker.object3D.visible = true;
    this.originMarkerText.object3D.visible = true;
    // window.cameraHudComponent.showHideProductsContainer(false);
    window.cameraHudComponent.showHideUtilButtons(false);
    if (window.gameManager.trackingInitialized) {
      setTimeout(() => {
        if (!window.tapPlaceCursorRef.placed) window.cameraHudComponent.showHidePlaceScenePopupContainer(true);
      }, 1000);
    }
    if (window.isDesktop) this.placeScene(new THREE.Vector3(0, 0, 0));
  },

  tick() {
    if (this.placed || !this.isEnabled || !this.getElementsCompleted) return;
    // Raycast from camera to 'ground'
    this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera);
    const intersects = this.raycaster.intersectObject(this.ground.object3D, true);
    // window.console.log('intersects', intersects)
    if (intersects.length > 0) {
      this.cursorLocation.x = intersects[0].point.x;
      this.cursorLocation.y = 0.02;
      this.cursorLocation.z = intersects[0].point.z;
    }
    // this.el.object3D.position.y = 0.1
    if (!this.firstUpdatePerformed) {
      this.el.object3D.position.copy(this.cursorLocation);
      this.firstUpdatePerformed = true;
    }
    else {
      this.el.object3D.position.lerp(this.cursorLocation, 0.7);
    }
    // this.el.object3D.rotation.y = this.threeCamera.rotation.y

    const camWorldPos = new THREE.Vector3();
    camWorldPos.setFromMatrixPosition(this.threeCamera.matrixWorld);
    this.el.object3D.rotation.y = Math.atan2(
      (camWorldPos.x - this.el.object3D.position.x),
      (camWorldPos.z - this.el.object3D.position.z),
    );
    // this.el.object3D.rotation.y += 3;
    // window.console.log('intersects, this.el.object3D:', this.el.object3D)
  },
};

export { tapPlaceCursorComponent };
