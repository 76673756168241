import gsap from 'gsap';
import Config from './config.js';
import AnalyticsManager from './analytics-manager.js';
import * as FirebaseManager from './firebase-manager.js';
import Utils from './utils.js';
import NeoGame from './neo-dirty-words.js';

import playerHealthBarFull from '../../assets/ui-images/health_bars/player_health_bar.png';
import playerHealthBarLow from '../../assets/ui-images/health_bars/player_health_bar_low.png';
import playerHealthBarSliderFull from '../../assets/ui-images/health_bars/player_health_bar_slider.png';
import playerHealthBarSliderLow from '../../assets/ui-images/health_bars/player_health_bar_slider_low.png';
// import 'regenerator-runtime/runtime';

const cameraHudComponent = {
  init() {
    window.cameraHudComponent = this;
    this.laserBtnTweenObj = {
      rotationZ: 0,
    };
    this.cam = document.getElementById('camera').components.camera.el.object3D.children[0];

    this.container = document.getElementById('container');

    this.factBox = document.getElementById('factBox');
    this.factBoxText = document.querySelector('.fact_box_text');
    this.factBoxBtn = document.querySelector('.fact_box_btn');
    this.factBoxLink = document.querySelector('#factBoxLink');
    this.factBoxLink.addEventListener('click', () => {
      // Analytics
      AnalyticsManager.sendContentClickEvent('LEARN MORE', 'Fact Box');
    });
    this.damageOverlay = document.getElementById('damageOverlay');

    this.copyLinkBtn = document.getElementById('copyLinkBtn');
    this.copyLinkBtn.onclick = this.copyLinkBtnPressed.bind(this);

    this.leaderboardContainer = document.querySelector('#leaderboardContainer');
    this.menuLeaderboardBtn = document.querySelector('#menuLeaderboardBtn');
    this.menuLeaderboardBtn.onclick = this.goToLeaderboardBtnPressed.bind(this);
    this.closeLeaderboardBtn = document.querySelector('#closeLeaderboardBtn');
    this.closeLeaderboardBtn.onclick = this.closeLeaderboardBtnPressed.bind(this);
    this.leaderboardLoadingMessage = document.querySelector('#leaderboardLoadingMessage');
    this.leaderboardContentScroll = document.querySelector('#leaderboardContentScroll');
    this.leaderboardTableEntryTemplate = document.querySelector('#leaderboardTableEntryTemplate');
    this.quitScreen = document.querySelector('#quitScreen');
    this.quitConfirmBtn = document.getElementById('quitConfirmBtn');
    this.quitConfirmBtn.onclick = this.quitConfirmBtnPressed.bind(this);
    this.quitCancelBtn = document.getElementById('quitCancelBtn');
    this.quitCancelBtn.onclick = this.quitCancelBtnPressed.bind(this);

    this.pauseBtn = document.getElementById('pauseBtn');
    this.pauseBtn.onclick = this.pauseBtnPressed.bind(this);
    this.pauseMenu = document.querySelector('#pauseMenu');
    this.pauseResumeBtn = document.getElementById('pauseResumeBtn');
    this.pauseResumeBtn.onclick = this.pauseResumeBtnPressed.bind(this);
    this.pauseLeaderboardBtn = document.getElementById('pauseLeaderboardBtn');
    this.pauseLeaderboardBtn.onclick = this.pauseLeaderboardBtnPressed.bind(this);
    this.pauseHowToPlayBtn = document.getElementById('pauseHowToPlayBtn');
    this.pauseHowToPlayBtn.onclick = this.pauseHowToPlayBtnPressed.bind(this);
    this.pauseQuitBtn = document.getElementById('pauseQuitBtn');
    this.pauseQuitBtn.onclick = this.pauseQuitBtnPressed.bind(this);

    this.welcomeScreen = document.querySelector('#welcomeScreen');
    this.welcomeScreenText = document.querySelector('#welcomeScreen-text');
    this.welcomeScreenImage = document.querySelector('#welcomeScreen-image');
    this.tutorialHandSwipe = document.querySelector('#tutorialHandSwipe');
    this.tutorialHandTap = document.querySelector('#tutorialHandTap');
    this.tutorialHandTapCircle = document.querySelector('#tutorialHandTapCircle');
    this.tutorialPagination = document.querySelector('#tutorialPagination');
    this.tutorialNextBtn = document.querySelector('#tutorialNextBtn');
    this.tutorialNextBtn.onclick = () => {
      this.tutorialNextBtnClicked();
    };
    this.tutorialSkipBtn = document.querySelector('#tutorialSkipBtn');
    this.tutorialSkipBtn.onclick = this.tutorialSkipPressed.bind(this);

    this.areYouReadyModal = document.querySelector('#areYouReadyModal');
    this.readyBtn = document.querySelector('#readyBtn');
    this.readyBtn.onclick = this.readyBtnPressed.bind(this);
    this.notReadyBtn = document.querySelector('#notReadyBtn');
    this.notReadyBtn.onclick = this.notReadyBtnPressed.bind(this);

    this.trackingPopupContainer = document.getElementById('trackingPopupContainer');
    this.placeSceneContainer = document.getElementById('placeSceneContainer');
    this.placeSceneBtn = document.getElementById('placeSceneBtn');
    this.placeSceneBtn.onclick = () => window.gameManager.placeScene();

    this.trackingText = document.getElementById('tracking-text');

    this.topHeader = document.querySelector('.top-header');
    this.scoreText = document.querySelector('#score-text');
    this.highScoreTexts = document.querySelectorAll('.highScoreText');
    this.playerHealthBarBg = document.querySelector('#playerHealthBarBg');
    this.playerHealthBar = document.querySelector('#playerHealthBar');
    this.menaceHealthBarBg = document.querySelector('#menaceHealthBarBg');
    this.menaceHealthBar = document.querySelector('#menaceHealthBar');

    this.topHeaderLvlNumber = document.querySelector('#topHeaderLvlNumber');

    this.utilButtons = document.getElementById('util-buttons');
    this.attackBtn = document.getElementById('attackBtn');
    // this.attackBtn.onclick = this.attackBtnPressed.bind(this);
    this.attackBtn.addEventListener('pointerdown', this.attackBtnPressed.bind(this));
    this.laserBtnProgress = document.getElementById('laserBtnProgress');

    this.levelIntroContainer = document.querySelector('#levelIntroContainer');
    this.levelIntroBottom = document.querySelector('#round_intro_bottom');
    this.levelIntroBottomShards = document.querySelector('#round_intro_bottom_shards');
    this.levelIntroBottomText = document.querySelector('#round_intro_bottom_text');

    this.levelupModal = document.querySelector('#levelupModal');
    this.levelupModalMessage = document.querySelector('#levelupModalMessage');
    this.levelupModalBtn = document.querySelector('#levelupModalBtn');
    this.levelupModalBtn.onclick = this.levelupModalBtnPressed.bind(this);

    this.enterNameScreen = document.querySelector('#enterNameScreen');
    this.enterNameScreenInput = document.querySelector('#enterNameScreenInput');
    this.enterNameNote = document.querySelector('#enterNameNote');
    this.sendScoreBtn = document.querySelector('#sendScoreBtn');
    this.sendScoreBtn.onclick = this.sendScoreBtnPressed.bind(this);
    this.skipSendScoreBtn = document.querySelector('#skipSendScoreBtn');
    this.skipSendScoreBtn.onclick = this.skipSendScoreBtnPressed.bind(this);

    this.gameOverScreen = document.querySelector('#gameOverScreen');
    this.goToLeaderboardBtn = document.querySelector('#goToLeaderboardBtn');
    this.goToLeaderboardBtn.onclick = this.goToLeaderboardBtnPressed.bind(this);
    this.playAgainBtn = document.querySelector('#playAgainBtn');
    this.playAgainBtn.onclick = this.playAgainPressed.bind(this);
    this.resultSharedImageContainer = document.querySelector('#resultSharedImageContainer');
    this.shareButtons = document.querySelector('#shareButtons');
    this.resultScoreTexts = document.querySelectorAll('.resultScoreText');
    // Share buttons
    this.shareBtnGeneric = document.querySelector('#shareBtnGeneric');
    this.shareBtnGeneric.shareBtnId = 'generic';
    this.shareBtnGeneric.onclick = this.shareBtnPressed.bind(this);
    this.shareBtnDownload = document.querySelector('#shareBtnDownload');
    this.shareBtnDownload.shareBtnId = 'download';
    this.shareBtnDownload.onclick = this.shareBtnPressed.bind(this);

    this.endGameScreen = document.querySelector('#endGameScreen');
    this.endGameTitle = document.querySelector('#endGame-title');
    this.endGameText = document.querySelector('#endGame-text');
    this.endGamePlayAgainBtn = document.querySelector('#endGamePlayAgainBtn');
    this.endGamePlayAgainBtn.onclick = this.playAgainPressed.bind(this);
    this.endGameReturnHomeBtn = document.querySelector('#endGameReturnHomeBtn');
    this.endGameReturnHomeBtn.onclick = this.playAgainPressed.bind(this);
    this.endGameLink = document.querySelector('#endGameLink');
    this.endGameLink.addEventListener('click', () => {
      // Analytics
      AnalyticsManager.sendContentClickEvent('LEARN MORE', 'End Game');
    });

    // Screen effects:
    this.smokeScreenEffect = document.querySelector('#smokeScreenEffect');
    this.shardsScreenEffect = document.querySelector('#shardsScreenEffect');
    this.blindScreenEffect = document.querySelector('#blindScreenEffect');
    this.crackScreenEffect = document.querySelector('#crackScreenEffect');

    this.setLaserBtnProgress(0, 0);
    this.showHideTrackingPopupContainer(false, true);
    this.showHidePlaceSceneContainer(false, true);
    this.showHideUtilButtons(false);
    this.setAttackBtnEnabled(false);

    // Generic button sound
    const allMainButtons = document.querySelectorAll('.button_main');
    for (let i = 0; i < allMainButtons.length; i++) {
      const btnText = allMainButtons[i].querySelector('.button_text');
      if (btnText) {
        allMainButtons[i].addEventListener('click', () => {
          window.audioManager.playButtonSound();
          // Analytics
          AnalyticsManager.sendContentClickEvent(btnText.innerHTML, allMainButtons[i].getAttribute('analytics_page_name'));
        });
      }
    }
    const allTransparentButtons = document.querySelectorAll('.button_transparent');
    for (let i = 0; i < allTransparentButtons.length; i++) {
      allTransparentButtons[i].addEventListener('click', () => {
        window.audioManager.playButtonSound();
        // Analytics
        AnalyticsManager.sendContentClickEvent(allTransparentButtons[i].innerText, allTransparentButtons[i].getAttribute('analytics_page_name'));
      });
    }
    const allOtherButtons = document.querySelectorAll('.fact_box_btn, #pauseBtn, .shareBtn');
    for (let i = 0; i < allOtherButtons.length; i++) {
      allOtherButtons[i].addEventListener('click', () => {
        window.audioManager.playButtonSound();
      });
    }

    // Prevent interaction with all images
    setTimeout(() => {
      document.querySelectorAll('img').forEach((img) => {
        // console.log('document.querySelectorAll - img:', img);
        img.setAttribute('draggable', false);
        img.setAttribute('oncontextmenu', 'return false;');
      });
    }, 200);

    this.subscribeToEvents();
  },

  subscribeToEvents() {
    this.el.sceneEl.addEventListener('onGameStarted', this.checkEnableCheats.bind(this));
    document.addEventListener('pointerdown', this.onPointerdown.bind(this));
  },

  onPointerdown(evt) {
    if (
      this.attackEnabled
      && evt.clientY > window.innerHeight * 0.72
      && evt.clientX > window.innerWidth * 0.3
      && evt.clientX < window.innerWidth * 0.7
    ) this.attackBtnPressed();
  },

  checkEnableCheats() {
    this.debugButtons = document.querySelector('#debugButtons');
    if (Utils.paramIsEnabled('enableDevCheats')) {
      this.debugButtons.style.display = null;
      this.debugButton_1 = document.querySelector('#debugButton_1');
      if (this.debugButton_1) this.debugButton_1.onclick = () => window.gameManager.hitEnemy();
      this.debugButton_2 = document.querySelector('#debugButton_2');
      if (this.debugButton_2) this.debugButton_2.onclick = () => window.gameManager.hitPlayer();
      this.debugButton_3 = document.querySelector('#debugButton_3');
      if (this.debugButton_3) this.debugButton_3.onclick = () => window.gameManager.onProjectileDeflected();
      this.debugButton_4 = document.querySelector('#debugButton_4');
      if (this.debugButton_4) this.debugButton_4.onclick = () => window.gameManager.winLevel();
      this.debugButton_5 = document.querySelector('#debugButton_5');
      if (this.debugButton_5) this.debugButton_5.onclick = () => window.enemyMonsterRef.setAttackEnabled(false);
    }
    else {
      this.debugButtons.remove();
    }
  },

  restartScene() {
    if (window.gameManager.restartingScene) return;
    window.console.log('camera-hud restartScene()');
    window.gameManager.restartScene();
  },

  showHideFactBox(newValue = true, bottomPosition = false, enableButton = false, text = '') {
    if (bottomPosition) this.factBox.classList.add('factBoxBottomPosition');
    else this.factBox.classList.remove('factBoxBottomPosition');

    if (newValue) {
      this.factBoxText.innerHTML = text || Config.defaultFactMessage;
      if (enableButton) this.factBoxBtn.classList.remove('hideAlpha');
      else this.factBoxBtn.classList.add('hideAlpha');
      this.factBox.classList.remove('hideAlpha');
    }
    else this.factBox.classList.add('hideAlpha');
  },

  showHideWelcomeScreen(newValue = true, instant = false) {
    if (newValue) {
      this.tutorialPage = 0;
      this.showTutorialPage(this.tutorialPage);
      this.welcomeScreen.style.display = null;
      this.welcomeScreen.classList.remove('hideAlphaDown');
    }
    else this.welcomeScreen.classList.add('hideAlphaDown');
  },

  tutorialNextBtnClicked() {
    this.tutorialPage += 1;
    if (this.tutorialPage < this.tutorialPagination.children.length) this.showTutorialPage(this.tutorialPage);
    else {
      this.showHideWelcomeScreen(false);
      this.showHideAreYouReady(true);
    }
    if (this.tutorialPage === 2) {
      this.tutorialNextBtn.querySelector('.button_text').innerHTML = 'FINISH';
    }
  },

  showHideAreYouReady(newValue = true, instant = false) {
    if (newValue) {
      this.areYouReadyModal.classList.remove('hideAlpha');
      AnalyticsManager.sendPageviewEvent('Ready');
    }
    else this.areYouReadyModal.classList.add('hideAlpha');
  },

  copyLinkBtnPressed() {
    navigator.clipboard.writeText(window.location.href);
    AnalyticsManager.sendCopyLink();
  },

  tutorialSkipPressed() {
    this.showHideWelcomeScreen(false);
    this.showHideAreYouReady(true);
  },

  readyBtnPressed() {
    this.showHideAreYouReady(false);
    this.finishTutorial();
  },

  notReadyBtnPressed() {
    this.showHideAreYouReady(false);
    this.showHideWelcomeScreen();
  },

  quitConfirmBtnPressed() {
    window.location.reload(); // TODO: Improve this?
  },

  quitCancelBtnPressed() {
    this.showHideQuitScreen(false);
    this.showHidePause(true);
  },

  showTutorialPage(pageNumber = 0) {
    this.welcomeScreenText.innerHTML = Config.tutorialPages[pageNumber].text;
    this.welcomeScreenImage.src = Config.tutorialPages[pageNumber].image;
    for (let i = 0; i < this.tutorialPagination.children.length; i++) {
      const element = this.tutorialPagination.children[i];
      if (i === pageNumber) element.classList.add('tutorialPaginationMarkerActive');
      else element.classList.remove('tutorialPaginationMarkerActive');
    }
    switch (pageNumber) {
      case 0:
        this.tutorialHandSwipe.classList.remove('hidden');
        this.tutorialHandTap.classList.add('hidden');
        this.tutorialHandTapCircle.classList.add('hidden');
        AnalyticsManager.sendPageviewEvent('How to Play');
        break;
      case 1:
        this.tutorialHandSwipe.classList.add('hidden');
        this.tutorialHandTap.classList.remove('hidden');
        this.tutorialHandTapCircle.classList.remove('hidden');
        break;
      case 2:
        this.tutorialHandSwipe.classList.add('hidden');
        this.tutorialHandTap.classList.add('hidden');
        this.tutorialHandTapCircle.classList.add('hidden');
        break;

      default:
        break;
    }
  },

  finishTutorial() {
    this.showHideWelcomeScreen(false);
    if (!window.gameManager.scenePlaced) this.showHideTrackingPopupContainer(true);
    window.gameManager.finishTutorial();
  },

  showHideTrackingPopupContainer(newValue = true, instant = false) {
    if (newValue) {
      // this.trackingPopupContainer.style.display = null;
      this.trackingPopupContainer.classList.remove('hideAlphaDown');
    }
    else {
      this.trackingPopupContainer.classList.add('hideAlphaDown');
    }
  },

  showHidePlaceSceneContainer(newValue = true, instant = false) {
    // this.placeSceneContainer.style.display = newValue ? null : 'none';
    if (newValue) this.placeSceneContainer.classList.remove('hideAlpha');
    else this.placeSceneContainer.classList.add('hideAlpha');
  },

  showHideLeaderboardContainer(newValue = true, desktopLoaderboardMode = false) {
    this.desktopLoaderboardMode = desktopLoaderboardMode;
    this.leaderboardContainer.removeAttribute('style'); // Remove the hidden override
    if (newValue) {
      this.leaderboardContainer.classList.remove('hideAlphaDown');
      this.leaderboardLoadingMessage.classList.remove('hideAlpha');
      this.leaderboardContentScroll.innerHTML = '';
      this.refreshLeaderboardData();
      AnalyticsManager.sendPageviewEvent('Leaderboard');
      if (desktopLoaderboardMode) {
        this.leaderboardContainer.classList.add('leaderboardContainer_desktop');
        setInterval(() => { // Keep refreshing the leaderboard
          this.refreshLeaderboardData();
        }, 60000);
      }
    }
    else this.leaderboardContainer.classList.add('hideAlphaDown');
  },

  refreshLeaderboardData() {
    FirebaseManager.GetLeaderboard().then((result) => {
      this.leaderboardContentScroll.innerHTML = ''; // Clear children
      // console.log('FirebaseManager.GetLeaderboard - result:', result);
      result.forEach((res, idx) => {
        const clonedEntry = this.leaderboardTableEntryTemplate.cloneNode(true);
        clonedEntry.removeAttribute('id');
        clonedEntry.querySelector('.leaderboardEntryPosition').innerHTML = idx + 1;
        clonedEntry.querySelector('.leaderboardEntryUserName').innerHTML = res.username;
        clonedEntry.querySelector('.leaderboardEntryScore').innerHTML = res.score.toLocaleString('en-US');
        clonedEntry.userID = res.userID;
        this.leaderboardContentScroll.appendChild(clonedEntry);
        if (localStorage.getItem('playerID') && res.userID === localStorage.getItem('playerID') && !this.desktopLoaderboardMode) {
          clonedEntry.classList.add('leaderboardTableEntryHighlight');
        }
      });
      if (result.length < Config.leaderboardMaxEntries) this.leaderboardContentScroll.classList.add('leaderboardContentScrollJustifyStart');
      else this.leaderboardContentScroll.classList.remove('leaderboardContentScrollJustifyStart');
      this.leaderboardLoadingMessage.classList.add('hideAlpha');
    });
  },

  showHideQuitScreen(newValue = true, instant = false) {
    if (newValue) this.quitScreen.classList.remove('hideAlpha');
    else this.quitScreen.classList.add('hideAlpha');
  },

  showHideEndGameScreen(newValue = true, instant = false) {
    if (newValue) {
      this.endGameTitle.innerHTML = window.gameManager.wonGame ? Config.winTitleText : Config.loseTitleText;
      this.endGameText.innerHTML = window.gameManager.wonGame ? Config.winMessageText : Config.loseMessageText;
      this.endGameScreen.classList.remove('hideAlpha');
    }
    else this.endGameScreen.classList.add('hideAlpha');
  },

  showHideUtilButtons(newValue = true, instant = false) {
    // this.utilButtons.style.display = newValue ? null : 'none';
    if (newValue) this.utilButtons.classList.remove('hideAlpha');
    else this.utilButtons.classList.add('hideAlpha');
  },

  setAttackBtnEnabled(newValue = true) {
    this.attackEnabled = newValue;
    if (newValue) {
      this.attackBtn.classList.remove('attackBtnDisabled');
      if (this.attackBtn.pressTween) this.attackBtn.pressTween.kill();
      window.audioManager.playButtonSound();
      // Tween
      this.attackBtn.pressTween = gsap.fromTo(this.attackBtn,
        {
          scale: 1,
        },
        {
          duration: 0.17,
          scale: 1.15,
          ease: 'sine.inOut',
          yoyo: true,
          repeat: 1,
        },
      );
    }
    else {
      this.attackBtn.classList.add('attackBtnDisabled');
    }
  },

  attackBtnPressed() {
    this.pressButtonTween(this.attackBtn);
    window.gameManager.hitEnemy();
  },

  showHideLevelIntroContainer(newValue = true, bottomBgImageSrc = null, bottomBgShardsImageSrc = null, bottomTextImageSrc = null) {
    if (newValue) {
      this.levelIntroBottom.src = bottomBgImageSrc;
      this.levelIntroBottomShards.src = bottomBgShardsImageSrc;
      this.levelIntroBottomText.src = bottomTextImageSrc;
      this.levelIntroContainer.classList.remove('hideLevelIntro');
    }
    else this.levelIntroContainer.classList.add('hideLevelIntro');
  },

  showHideLevelupModal(newValue = true, instant = false) {
    if (newValue) {
      this.levelupModalMessage.innerHTML = `You defeated ${window.gameManager.getCurrentLevelData().enemyName}!`;
      this.levelupModal.classList.remove('hideAlpha');
      AnalyticsManager.sendContentLevelupEvent(window.gameManager.getCurrentLevelData().levelName, window.gameManager.getCurrentLevelData().enemyName);
    }
    else this.levelupModal.classList.add('hideAlpha');
    window.cameraHudComponent.showHideFactBox(newValue, true, false, window.gameManager.getCurrentLevelData().factMessage);
  },

  levelupModalBtnPressed() {
    this.showHideLevelupModal(false);
    window.gameManager.nextLevel();
  },

  showHideEnterNameScreen(newValue = true, instant = false) {
    if (newValue) {
      this.enterNameScreen.classList.remove('hideAlpha');
      this.showHideFactBox(true, false);
    }
    else this.enterNameScreen.classList.add('hideAlpha');
  },

  sendScoreBtnPressed() {
    if (this.enterNameScreenInput.checkValidity()) {
      if (NeoGame.checkForProfanity(this.enterNameScreenInput.value)) {
        this.enterNameNote.innerHTML = 'Minimum of 3 characters. No profanity allowed.';
        this.showEnterNameError(true);
      }
      else {
        this.enterNameNote.innerHTML = 'No more than 10 characters. Minimum of 3 characters.';
        this.showEnterNameError(false);
        window.gameManager.sendScore(this.enterNameScreenInput.value);
        this.showHideEnterNameScreen(false);
        this.showHideGameOverScreen(true);
      }
    }
    else {
      this.enterNameNote.innerHTML = 'No more than 10 characters. Minimum of 3 characters.';
      this.showEnterNameError(true);
    }
  },

  showEnterNameError(newValue = true) {
    if (newValue) this.enterNameScreenInput.classList.add('inputError');
    else this.enterNameScreenInput.classList.remove('inputError');
  },

  skipSendScoreBtnPressed() {
    this.showHideEnterNameScreen(false);
    this.showHideGameOverScreen(true);
  },

  showHideGameOverScreen(newValue = true, instant = false) {
    if (newValue) {
      this.updateShareImage();
      this.gameOverScreen.classList.remove('hideAlpha');
      this.showHideFactBox(false);
    }
    else this.gameOverScreen.classList.add('hideAlpha');
  },

  updateShareImage() {
    console.log('updateShareImage()');
    // Disable button:
    this.shareButtons.classList.add('disableButtons');
    setTimeout(async () => {
      // Update text:
      this.resultScoreTexts.forEach((textElement) => {
        textElement.innerHTML = Config.shareImageText.replace(
          '[score-number]',
          window.gameManager.currentScore.toLocaleString('en-US'),
        );
      });

      // Save blob:
      this.shareImageCanvas = await Utils.getElementAsCanvas(this.resultSharedImageContainer);
      console.log('updateShareImage - canvas:', this.shareImageCanvas);
      this.shareImageCanvas.toBlob((blob) => {
        this.shareImageTextBlob = blob;
        console.log('updateShareImage - blob:', this.shareImageTextBlob);
        this.shareButtons.classList.remove('disableButtons');
      });
    }, 1);
  },

  async shareBtnPressed(evt) {
    console.log('shareBtnPressed - target:', evt.target);
    if (evt.target.shareBtnId === 'generic') {
      const file = new File([this.shareImageTextBlob], 'metallic-menace.png', { type: 'image/png' });
      console.log('shareBtnPressed - file:', file);
      const shareText = Config.shareText.replace(
        '[score-number]',
        window.gameManager.currentScore.toLocaleString('en-US'),
      ).replace(
        '[game-url]',
        Config.shareURL, //window.location.href,
      );
      console.log('shareBtnPressed - shareText:', shareText);
      Utils.shareContent({
        title: 'Attack Of The Metallic Menace',
        text: shareText,
        // url: window.location.href,
        files: [file],
      });
    }
    else if (evt.target.shareBtnId === 'download') {
      console.log('shareBtnPressed - Download');
      // Utils.saveElementScreenshot(this.resultSharedImageContainer);
      Utils.saveCanvasScreenshot(this.shareImageCanvas);
    }
  },

  goToLeaderboardBtnPressed() {
    this.showHideLeaderboardContainer(true);
  },

  closeLeaderboardBtnPressed() {
    this.showHideLeaderboardContainer(false);
    if (window.gameManager.gameStatus === Config.gameStatus.finished) {
      this.showHideEndGameScreen(true);
    }
  },

  playAgainPressed() {
    window.location.reload(); // TODO: Improve this?
  },

  showHidePause(newValue = true) {
    if (newValue) {
      if (this.poiInfoPanelOpen) return;
      this.pauseMenu.classList.remove('hideAlpha');
    }
    else {
      this.pauseMenu.classList.add('hideAlpha');
    }
    this.showHideFactBox(newValue, true);
  },

  pauseBtnPressed() {
    window.gameManager.setPaused(true);
  },

  pauseResumeBtnPressed() {
    window.gameManager.setPaused(false);
  },

  pauseLeaderboardBtnPressed() {
    this.showHideLeaderboardContainer(true);
  },

  pauseHowToPlayBtnPressed() {
    this.showHidePause(false);
    window.gameManager.showTutorial();
  },

  pauseQuitBtnPressed() {
    this.showHidePause(false);
    this.showHideQuitScreen(true);
  },

  showHideTopHeader(newValue = true) {
    if (newValue) {
      if (this.poiInfoPanelOpen) return;
      this.topHeader.classList.remove('top-header-hide');
    }
    else {
      this.topHeader.classList.add('top-header-hide');
    }
  },

  updatePlayerHealthBar() {
    const healthNormalized = window.gameManager.playerHealth / Config.playerMaxHealth;
    this.playerHealthBarBg.src = healthNormalized >= 0.5 ? playerHealthBarFull : playerHealthBarLow;
    this.playerHealthBar.src = healthNormalized >= 0.5 ? playerHealthBarSliderFull : playerHealthBarSliderLow;
    const healthValue = (healthNormalized * 84) - 100 + 8;
    // this.playerHealthBar.style.transform = `scaleX(${healthNormalized})`;
    // this.playerHealthBar.style.transform = `translateX(${healthValue}%)`;
    if (this.playerHealthBarTween) this.playerHealthBarTween.kill();
    this.playerHealthBarTween = gsap.to(this.playerHealthBar, {
      x: `${healthValue}%`,
      duration: 0.4,
      ease: 'power4.out',
    });
  },

  updateEnemyHealthBar(updateBg = false) {
    const healthNormalized = window.gameManager.enemyHealth / window.gameManager.getCurrentLevelData().enemyMaxHealth;
    if (updateBg) this.menaceHealthBarBg.src = window.gameManager.getCurrentLevelData().enemyHealthBar;
    this.menaceHealthBar.src = healthNormalized >= 0.5 ? window.gameManager.getCurrentLevelData().enemyHealthBarSlider : window.gameManager.getCurrentLevelData().enemyHealthBarSliderLow;
    const healthValue = 100 - 8 - (healthNormalized * 84);
    // this.menaceHealthBar.style.transform = `scaleX(${window.gameManager.enemyHealth / window.gameManager.getCurrentLevelData().enemyMaxHealth})`;
    // this.menaceHealthBar.style.transform = `translateX(${healthValue}%)`;
    if (this.menaceHealthBarTween) this.menaceHealthBarTween.kill();
    this.menaceHealthBarTween = gsap.to(this.menaceHealthBar, {
      x: `${healthValue}%`,
      duration: 0.4,
      ease: 'power4.out',
    });
  },

  updateScore() {
    this.scoreText.innerHTML = window.gameManager.currentScore.toLocaleString('en-US');
    this.highScoreTexts.forEach((highScoreElement) => {
      highScoreElement.innerHTML = window.gameManager.currentScore.toLocaleString('en-US');
    });

    // Tween
    if (this.updateScoreTwn) this.updateScoreTwn.kill();
    this.updateScoreTwn = gsap.fromTo(this.scoreText,
      {
        scale: 1,
      },
      {
        duration: 0.06,
        scale: 1.2,
        ease: 'sine.out',
        yoyo: true,
        repeat: 1,
      },
    );
  },

  updateLevel() {
    this.topHeaderLvlNumber.innerHTML = window.gameManager.currentLevel + 1;
  },

  setLaserBtnProgress(newRotation, tweenDuration = 0.5, callback = null) {
    if (this.laserProgressTwn) this.laserProgressTwn.kill();
    let maskValue;
    this.laserProgressTwn = gsap.to(this.laserBtnTweenObj,
      {
        duration: tweenDuration,
        rotationZ: newRotation,
        ease: 'none',
        onUpdate: () => {
          if (this.laserProgressTwn) {
            maskValue = `conic-gradient(from 0deg, rgb(100 0 0), rgb(0 100 0) ${this.laserBtnTweenObj.rotationZ}deg, #00000000 0deg)`;
            this.laserBtnProgress.style.setProperty('-webkit-mask-image', maskValue);
            this.laserBtnProgress.style.setProperty('mask-image', maskValue);
          }
        },
        onComplete: () => {
          maskValue = `conic-gradient(from 0deg, rgb(100 0 0), rgb(0 100 0) ${newRotation}deg, #00000000 0deg)`;
          this.laserBtnProgress.style.setProperty('-webkit-mask-image', maskValue);
          this.laserBtnProgress.style.setProperty('mask-image', maskValue);
          if (callback && typeof callback === 'function') callback.call();
        },
        callbackScope: this,
      },
    );
  },

  pressButtonTween(element, callback = null, instant = false) {
    if (element.pressTween) element.pressTween.kill();
    // window.audioManager.playButtonSound();
    // Tween
    element.pressTween = gsap.fromTo(element,
      {
        scale: 1,
      },
      {
        duration: instant ? 0 : 0.04,
        scale: 0.95,
        ease: 'sine.out',
        yoyo: true,
        repeat: 1,
        onComplete: () => {
          if (callback && typeof callback === 'function') callback.call();
        },
      },
    );
  },

  showDamage() {
    if (this.damageTween) this.damageTween.kill();
    this.damageTween = gsap.fromTo(this.damageOverlay,
      {
        opacity: 0,
      },
      {
        duration: 0.4,
        opacity: 1,
        ease: 'power1.out',
        yoyo: true,
        repeat: 1,
      },
    );
  },

  showHideSmokeScreenEffect(newValue = true) {
    if (newValue) this.smokeScreenEffect.classList.remove('hideAlpha');
    else this.smokeScreenEffect.classList.add('hideAlpha');
  },

  showHideShardsScreenEffect(newValue = true) {
    if (newValue) this.shardsScreenEffect.classList.remove('hideAlpha');
    else this.shardsScreenEffect.classList.add('hideAlpha');
  },

  showHideBlindScreenEffect(newValue = true) {
    if (newValue) this.blindScreenEffect.classList.remove('hideAlpha');
    else this.blindScreenEffect.classList.add('hideAlpha');
  },

  showHideCrackScreenEffect(newValue = true) {
    if (newValue) this.crackScreenEffect.classList.remove('hideAlpha');
    else this.crackScreenEffect.classList.add('hideAlpha');
  },
};

export { cameraHudComponent };
