import Utils from './utils.js';

const inputManagerComponent = {
  init() {
    window.inputManager = this;

    this.initialMousePosition = new THREE.Vector2();
    this.currentMousePosition = new THREE.Vector2();

    this.subscribeToEvents();
  },

  subscribeToEvents() {
    if (Utils.paramIsEnabled('bypassAR')) {
      document.addEventListener('mousedown', this.onMouseDown.bind(this));
      document.addEventListener('mousemove', this.onMouseMove.bind(this));
      document.addEventListener('mouseup', this.onMouseUp.bind(this));
    }
    else {
      document.addEventListener('touchstart', this.onTouchStart.bind(this));
      document.addEventListener('touchmove', this.onTouchMove.bind(this));
      document.addEventListener('touchend', this.onTouchEnd.bind(this));
    }
  },

  // --- TOUCH ---
  onTouchStart(evt) {
    if (evt && evt.touches) {
      if (this.inputTimer) clearTimeout(this.inputTimer); // Stop setTimeout
      this.currentMousePosition.set(evt.touches[0].clientX, evt.touches[0].clientY);
      window.gameManager.aCamera.setAttribute('raycaster', 'enabled', true);
      this.inputIsTouching = true;
      // console.log('[1] JS onTouchStart - this.inputIsTouching:', this.inputIsTouching, ', this.currentMousePosition:', this.currentMousePosition);
    }
  },

  onTouchMove(evt) {
    if (evt && evt.touches) {
      this.currentMousePosition.set(evt.touches[0].clientX, evt.touches[0].clientY);
    }
  },

  onTouchEnd(evt) {
    if (this.inputTimer) clearTimeout(this.inputTimer); // Stop setTimeout
    this.inputTimer = setTimeout(() => {
      window.gameManager.aCamera.setAttribute('raycaster', 'enabled', false);
      this.inputIsTouching = false;
      this.currentMousePosition.set(-1, -1);
    }, 10);
  },

  // --- MOUSE ---
  onMouseDown(evt) {
    if (this.inputTimer) clearTimeout(this.inputTimer); // Stop setTimeout
    this.currentMousePosition.set(evt.clientX, evt.clientY);
    window.gameManager.aCamera.setAttribute('raycaster', 'enabled', true);
    this.inputIsTouching = true;
  },

  onMouseMove(evt) {
    this.currentMousePosition.set(evt.clientX, evt.clientY);
  },

  onMouseUp(evt) {
    if (this.inputTimer) clearTimeout(this.inputTimer); // Stop setTimeout
    this.inputTimer = setTimeout(() => {
      window.gameManager.aCamera.setAttribute('raycaster', 'enabled', false);
      this.inputIsTouching = false;
      this.currentMousePosition.set(-1, -1);
    }, 10);
  },
};

export { inputManagerComponent };
