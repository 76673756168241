/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */

export default class AnalyticsManager {
  static sendPageviewEvent(pageName) {
    console.log(`%cAnalyticsManager sendPageviewEvent() - pageName: ${pageName}`, 'color: green; background: black;');
    window.dataLayer.push({
      event: 'pageview',
      title: pageName,
    });
  }

  static sendContentClickEvent(buttonName, pageName) {
    console.log(`%cAnalyticsManager sendContentClickEvent() - buttonName: ${buttonName} - pageName: ${pageName}`, 'color: green; background: black;');
    window.dataLayer.push({
      event: 'engagement.content_click',
      title: pageName,
      value: buttonName,
    });
  }

  static sendContentLevelupEvent(levelName, monsterName) {
    console.log(`%cAnalyticsManager sendContentLevelupEvent() - levelName: ${levelName} - monsterName: ${monsterName}`, 'color: green; background: black;');
    window.dataLayer.push({
      event: 'engagement.content_levelup',
      title: levelName,
      value: `${levelName} - ${monsterName}`,
    });
  }

  static sendUnsupportedBrowser() {
    const browserInfo = navigator.userAgent;
    console.log(`%cAnalyticsManager sendUnsupportedBrowser() - browserInfo: ${browserInfo}`, 'color: green; background: black;');
    window.dataLayer.push({
      event: 'unsupportedPage',
      value: browserInfo,
    });
  }

  static sendCopyLink() {
    const browserInfo = navigator.userAgent;
    console.log(`%cAnalyticsManager sendCopyLink() - browserInfo: ${browserInfo}`, 'color: green; background: black;');
    window.dataLayer.push({
      event: 'copyLink',
      value: browserInfo,
    });
  }
}
