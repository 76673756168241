import gsap from 'gsap';

// gsap.defaults({ ease: 'none' });

const svgns = 'http://www.w3.org/2000/svg';
const root = document.querySelector('#swipe-effect-svg');
const ease = 0.8;
let isDown = false;

const pointer = {
  x: window.innerWidth / 2,
  y: window.innerHeight / 2,
};

document.addEventListener('pointerdown', (event) => {
  isDown = true;
  updatePointer(event);
  restartLines();
});
document.addEventListener('pointerup', (event) => {
  isDown = false;
});
document.addEventListener('pointermove', (event) => {
  if (isDown) {
    updatePointer(event);
  }
});

function updatePointer(event) {
  pointer.x = event.clientX;
  pointer.y = event.clientY;
}

let leader = pointer;
const lines = [];

const total = 16;
for (let i = 0; i < total; i++) {
  leader = createLine(leader, i);
  lines.push(leader);
}

function restartLines() {
  for (let i = 0; i < lines.length; i++) {
    gsap.set(lines[i], {
      x: pointer.x,
      y: pointer.y,
    });
    lines[i].setAttribute('x2', pointer.x);
    lines[i].setAttribute('y2', pointer.y);
  }
}

function createLine(lineLeader, i) {
  const line = document.createElementNS(svgns, 'line');
  root.appendChild(line);

  const t = i / (total - 1); // Normalized position (0 to 1)
  gsap.set(line, {
    x: 0,
    y: 0,
    // alpha: (total - i) / total, // Linear
    alpha: 1 - t * t, // Quadratic formula for smoother gradient
    // strokeWidth: 20 * ((total - i) / total), // Linear
    // strokeWidth: 20 * (1 - t * t), // Quadratic formula for smoother fade-out gradient
    strokeWidth: 20 * (4 * t * (1 - t)), // Quadratic formula for quick rise and smooth fall
  });

  gsap.to(line, {
    duration: 1000,
    x: '+=1',
    y: '+=1',
    repeat: -1,
    modifiers: {
      x: () => {
        const posX = gsap.getProperty(line, 'x');
        const leaderX = gsap.getProperty(lineLeader, 'x');

        const x = posX + (leaderX - posX) * ease;
        line.setAttribute('x2', leaderX * 1.05 - x * 1.05);
        return x;
      },
      y: () => {
        const posY = gsap.getProperty(line, 'y');
        const leaderY = gsap.getProperty(lineLeader, 'y');

        const y = posY + (leaderY - posY) * ease;
        line.setAttribute('y2', leaderY * 1.05 - y * 1.05);
        return y;
      },
    },
  });

  return line;
}
