/* eslint-disable no-lonely-if */
// import { EXRLoader } from 'three/addons/loaders/EXRLoader.js';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js';
import gsap from 'gsap';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import Config from './config.js';
import Utils from './utils.js';
import AnalyticsManager from './analytics-manager.js';
import glowTexture from '../../assets/textures/glow.png';
import './swipe-effect.js';

/* import px from '../../assets/textures/cubemap/posx.jpg';
import nx from '../../assets/textures/cubemap/negx.jpg';
import py from '../../assets/textures/cubemap/posy.jpg';
import ny from '../../assets/textures/cubemap/negy.jpg';
import pz from '../../assets/textures/cubemap/posz.jpg';
import nz from '../../assets/textures/cubemap/negz.jpg'; */
import hdriTexture from '../../assets/textures/cubemap/courtyard.exr';
import * as FirebaseManager from './firebase-manager.js';

const gameManagerComponent = {
  init() {
    if (window.preventAppInit) return;
    window.gameManager = this;
    this.currentLevel = 0;
    if (Utils.getParamValue('initialLevel')) this.currentLevel = parseFloat(Utils.getParamValue('initialLevel'));
    this.gameStatus = Config.gameStatus.stopped;
    this.currentScore = 0;
    this.currentLaserCharge = 0;
    this.laserIsEnabled = false;
    this.wonGame = false;

    this.textureLoader = new THREE.TextureLoader();

    this.aScene = document.querySelector('a-scene');
    this.aScene.object3D.name = 'aScene';
    this.aCamera = document.getElementById('camera');
    this.hiderPlane = document.querySelector('#hiderPlane');

    this.originMarker = document.getElementById('originMarker');

    this.sceneOrigin = document.getElementById('sceneOrigin');
    this.sceneOrigin.object3D.name = 'sceneOrigin';
    this.sceneOrigin.object3D.originalScale = this.sceneOrigin.getAttribute('finalScale');
    this.sceneOrigin.object3D.scale.set(0.00001, 0.00001, 0.00001);

    this.reflectionSphere = document.querySelector('#reflectionSphere');
    this.topDarkOverlay = document.querySelector('#top_dark_overlay');

    // Lights:
    this.hemisphereLight = document.getElementById('hemisphereLight');

    this.el.sceneEl.renderer.sortObjects = true;

    if (Utils.paramIsEnabled('enableDebug')) {
      const debugObjects = this.el.sceneEl.querySelector('#debugObjects');
      debugObjects.setAttribute('visible', true);
    }
    if (Utils.paramIsEnabled('fps')) {
      this.statsDebug = new Stats();
      this.statsDebug.showPanel(1); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(this.statsDebug.dom);
    }
    if (Utils.paramIsEnabled('enableDevCheats')) {
      this.enableCheats();
    }
  },

  customInit() {
    if (!window.preventAppInit) {
      this.subscribeToEvents();

      // Load cubemap:
      /* const urls = [
        px, nx,
        py, ny,
        pz, nz,
      ];
      const reflectionCube = new THREE.CubeTextureLoader().load(urls);
      this.el.sceneEl.object3D.environment = reflectionCube; */

      new EXRLoader().load(hdriTexture, (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        // exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
        // exrBackground = texture;
        this.el.sceneEl.object3D.environment = texture;
      });
    }
  },

  subscribeToEvents() {
    this.el.sceneEl.addEventListener('onProjectileDeflected', this.onProjectileDeflected.bind(this));
  },

  enableCheats() {
    document.addEventListener('keydown', (evt) => {
      if (evt.key === 'm') {
        this.hitEnemy();
      }
      else if (evt.key === 'p') {
        this.hitPlayer();
      }
      else if (evt.key === 'l') {
        this.onProjectileDeflected();
      }
      else if (evt.key === 'k') {
        this.winLevel();
      }
    });
  },

  onProjectileDeflected() {
    if (this.gameStatus !== Config.gameStatus.playing) return;
    this.offsetScore(Config.scorePerDeflect);
    if (!this.laserIsEnabled) {
      this.setLaserCharge(this.currentLaserCharge + 1);
    }
  },

  startGame() {
    document.querySelector('#main-logo').classList.add('hideAlpha');
    this.playerHealth = Config.playerMaxHealth;

    setTimeout(() => {
      this.showTutorial();
    }, 1500);
    this.el.sceneEl.emit('onGameStarted');

    if (window.isDesktop) {
      this.hiderPlane.object3D.visible = false;
    }
  },

  showTutorial() {
    let welcomeScreenEnabled = true;
    if (Utils.paramIsEnabled('skipMainMenu')) welcomeScreenEnabled = false;
    if (welcomeScreenEnabled) {
      window.cameraHudComponent.showHideWelcomeScreen(true); // Show welcome screen
    }
    else {
      this.finishTutorial();
    }
  },

  finishTutorial() {
    ///
    this.welcomeScreenCompleted = true;
    // window.audioManager.playButtonSound();

    /* if (window.isDesktop) {
      setTimeout(() => {
        this.placeScene();
      }, 500);
    }
    else {
      this.enableSceneScan();
    } */
    if (!this.scenePlaced) this.enableSceneScan();
    this.setGameplayPaused(false);
  },

  /* xrimagefound(e) {
    this.imageTargetFound = true;
    console.log('xrimagefound - e:', e);
    if (this.showTrackingMessageTimeout) clearTimeout(this.showTrackingMessageTimeout); // Stop setTimeout
    if (this.welcomeScreenCompleted && !this.activatedNoCardMode) {
      if (!this.scenePlaced) {
        // window.cameraHudComponent.showHidePlaceSceneContainer(true);
        // window.cameraHudComponent.showHideTrackingPopupContainer(false);
        this.placeScene(); // Place scene automatically when the Image Target is detected
      } else {
        window.cameraHudComponent.showHidePlaceSceneContainer(false);
        window.cameraHudComponent.showHideTrackingPopupContainer(false);
        window.cameraHudComponent.showHideUtilButtons(true);
      }
    }
  }, */

  /* xrimagelost(e) {
    this.imageTargetFound = false;
    console.log('xrimagelost - e.detail:', e.detail);
    if (this.welcomeScreenCompleted && !this.activatedNoCardMode) {
      if (this.showTrackingMessageTimeout) clearTimeout(this.showTrackingMessageTimeout); // Stop setTimeout
      this.showTrackingMessageTimeout = setTimeout(() => {
        window.cameraHudComponent.showHidePlaceSceneContainer(false);
        window.cameraHudComponent.showHideTrackingPopupContainer(true);
        window.cameraHudComponent.showHideUtilButtons(false);
      }, 1500);
    }
  }, */

  tick() {
    if (this.statsDebug) this.statsDebug.begin();
    if (this.statsDebug) this.statsDebug.end();
  },

  placeScene() {
    if (this.scenePlaced) return;
    console.warn('gameManager placeScene()');
    this.scenePlaced = true;
    window.cameraHudComponent.showHidePlaceSceneContainer(false);
    window.cameraHudComponent.showHideTrackingPopupContainer(false);
    this.sceneOrigin.object3D.position.copy(window.tapPlaceCursorRef.el.object3D.position);
    this.sceneOrigin.object3D.rotation.y = window.tapPlaceCursorRef.el.object3D.rotation.y;
    setTimeout(() => {
      window.audioManager.playBattleMusic();
      gsap.to(this.sceneOrigin.object3D.scale, { // Appear scene model
        x: this.sceneOrigin.object3D.originalScale,
        y: this.sceneOrigin.object3D.originalScale,
        z: this.sceneOrigin.object3D.originalScale,
        duration: 0.2,
        ease: 'back.out',
        onStart: () => {
        },
        onComplete: () => {
          setTimeout(() => {
            this.startRound();
          }, 200);
        },
      });
    }, 300);
  },

  resetRoundData() {
    this.playerHealthAtStartOfRound = this.playerHealth;
    this.enemyHealth = this.getCurrentLevelData().enemyMaxHealth;
    this.setGameStatus(Config.gameStatus.playing);
    this.setLaserCharge(0);
    this.setPaused(false);
  },

  startRound() {
    this.resetRoundData();
    window.enemyMonsterRef.initMonster(this.currentLevel);

    // Show intro UI
    window.cameraHudComponent.showHideLevelIntroContainer(
      true,
      this.getCurrentLevelData().titleBgImage,
      this.getCurrentLevelData().titleBgImageShards,
      this.getCurrentLevelData().titleTextImage,
    );
    window.cameraHudComponent.showHideUtilButtons(false);
    setTimeout(() => {
      window.enemyMonsterRef.showHideMonster(true);
    }, 1700);
    setTimeout(() => {
      window.cameraHudComponent.showHideLevelIntroContainer(false);
      window.cameraHudComponent.showHideUtilButtons(true);
    }, 4200);

    window.cameraHudComponent.showHideTopHeader(true);
    window.cameraHudComponent.updatePlayerHealthBar();
    window.cameraHudComponent.updateEnemyHealthBar(true);
    window.cameraHudComponent.updateScore();
    window.cameraHudComponent.updateLevel();

    // Screen effects:
    switch (this.currentLevel) {
      case 0:
        break;
      case 1:
        window.cameraHudComponent.showHideShardsScreenEffect(true);
        break;
      case 2:
        window.cameraHudComponent.showHideSmokeScreenEffect(true);
        window.cameraHudComponent.showHideShardsScreenEffect(false);
        break;
      case 3:
        window.cameraHudComponent.showHideSmokeScreenEffect(false);
        window.cameraHudComponent.showHideShardsScreenEffect(false);
        window.cameraHudComponent.showHideBlindScreenEffect(true);
        break;
      case 4:
        window.cameraHudComponent.showHideSmokeScreenEffect(true);
        window.cameraHudComponent.showHideShardsScreenEffect(true);
        window.cameraHudComponent.showHideBlindScreenEffect(true);
        break;

      default:
        break;
    }
  },

  setGameStatus(newGameStatus = Config.gameStatus.stopped) {
    this.gameStatus = newGameStatus;
  },

  setLaserCharge(newValue, twnDur = 0.5) {
    if (newValue > this.getCurrentLevelData().swipesToChargeLaser) return;
    // console.log('setLaserCharge - newValue:', newValue, ', twnDur:', twnDur);
    this.currentLaserCharge = newValue;
    window.cameraHudComponent.setLaserBtnProgress(
      360 * (this.currentLaserCharge / this.getCurrentLevelData().swipesToChargeLaser),
      twnDur,
      this.checkLaserCharge.bind(this),
    );
  },

  checkLaserCharge() {
    if (this.currentLaserCharge >= this.getCurrentLevelData().swipesToChargeLaser) {
      // Enable laser
      this.laserIsEnabled = true;
      this.laserPressComboAmount = 0;
      window.cameraHudComponent.setAttackBtnEnabled(true);
      setTimeout(() => {
        this.setLaserCharge(0, Config.timeToDepleteLaserCharge); //  Start depleting laser charge
      }, 400);
    }
    else {
      // Disable laser
      this.laserIsEnabled = false;
      window.cameraHudComponent.setAttackBtnEnabled(false);
    }
  },

  enableSceneScan() {
    // this.activatedNoCardMode = true;
    /* XR8.XrController.configure({
      imageTargets: [], // Remove Image Target
    }); */
    this.aScene.emit('recenter');
    gsap.from(this.originMarker.object3D.scale, {
      x: 0.001,
      y: 0.001,
      z: 0.001,
      duration: 0.5,
      ease: 'back.out',
      onStart: () => {
        setTimeout(() => {
          this.originMarker.setAttribute('visible', true);
        }, 50);
      },
    });
    window.tapPlaceCursorRef.startGame();
  },

  placeSceneWithoutCard() {
    // this.sceneOrigin.object3D.originalScale = 0.4;
    this.placeScene();
  },

  adjustDesktopHierarchy() {
    this.aScene.object3D.attach(this.sceneOrigin.object3D);
    this.sceneOrigin.setAttribute('position', { x: 0, y: 0, z: -1 });
    this.sceneOrigin.setAttribute('rotation', { x: 0, y: 0, z: 0 });
  },

  hitPlayer() {
    if (this.gameStatus !== Config.gameStatus.playing) return;
    window.cameraHudComponent.showDamage();
    window.audioManager.playSound('playerDamageAudio');
    if (Utils.paramIsEnabled('godMode')) return;
    this.offsetPlayerHealth(-this.getCurrentLevelData().enemyDamage); // Damage
    if (this.playerHealth <= 0) {
      this.loseGame();
    }
  },

  offsetPlayerHealth(healthOffset) {
    this.playerHealth += healthOffset;
    this.playerHealth = THREE.MathUtils.clamp(this.playerHealth, 0, Config.playerMaxHealth);
    window.cameraHudComponent.updatePlayerHealthBar();

    // Crack effect:
    const healthNormalized = this.playerHealth / Config.playerMaxHealth;
    this.showHideCrackScreenEffect(healthNormalized < 0.5);
  },

  showHideCrackScreenEffect(newValue = true) {
    window.cameraHudComponent.showHideCrackScreenEffect(newValue);
    if (newValue && !this.screenCrackEnabled) {
      window.audioManager.playSound('screenCrackAudio');
    }
    this.screenCrackEnabled = newValue; // To prevent swipe input on that area
  },

  isCrackPreventingInput(mousePositionY) {
    return this.screenCrackEnabled && mousePositionY > window.innerHeight * 0.57;
  },

  hitEnemy() {
    if (this.gameStatus !== Config.gameStatus.playing) return;
    this.offsetEnemyHealth(-1); // Damage
    this.offsetScore(Config.scorePerHit);
    this.laserPressComboAmount += 1;
    if (this.laserPressComboAmount === Config.buttonPressAmountForComboAttack) {
      this.getCombo();
    }
    window.enemyMonsterRef.damage();
    // console.log('hitEnemy() - this.enemyHealth:', this.enemyHealth, ', this.laserPressComboAmount:', this.laserPressComboAmount);
    if (this.enemyHealth <= 0) {
      this.winLevel();
    }
    this.showLaserEffect();
  },

  showLaserEffect() {
    const laserEl = this.el.sceneEl.components.pool__laser.requestEntity();
    laserEl.play();
    laserEl.components['laser'].appear();

    const damageBadgeEl = this.el.sceneEl.components.pool__damage_badge.requestEntity();
    damageBadgeEl.play();
    damageBadgeEl.components['damage-badge'].appear();
    // console.log('pool__damage_badge', this.el.sceneEl.components.pool__damage_badge.availableEls.length);
  },

  getCombo() {
    this.offsetScore(Config.scoreForComboAttack);
    // window.audioManager.playComboSound();
  },

  offsetEnemyHealth(healthOffset) {
    this.enemyHealth += healthOffset;
    this.enemyHealth = THREE.MathUtils.clamp(this.enemyHealth, 0, this.getCurrentLevelData().enemyMaxHealth);
    window.cameraHudComponent.updateEnemyHealthBar();
  },

  offsetScore(scoreOffset) {
    this.currentScore += scoreOffset;
    window.cameraHudComponent.updateScore();
  },

  winLevel() {
    if (this.gameStatus !== Config.gameStatus.playing) return;
    this.el.sceneEl.emit('onWinLevel', null, false);
    this.setGameStatus(Config.gameStatus.stopped);
    window.enemyMonsterRef.defeated();

    // Score:
    this.offsetScore(this.getCurrentLevelData().pointsPerWin);
    if (this.playerHealth === this.playerHealthAtStartOfRound) { // Perfect level bonus
      this.offsetScore(Config.scoreForNoDagameReceivedLevel);
    }

    // Check next level:
    setTimeout(() => {
      if (this.currentLevel + 1 < Object.keys(Config.levelsData[Config.activeTheme]).length) { // There is a next round
        // console.log('winLevel() - There is a next round.', this.currentLevel + 1, '<', Object.keys(Config.levelsData[Config.activeTheme]).length);
        window.cameraHudComponent.showHideLevelupModal(true);
        window.cameraHudComponent.showHideUtilButtons(false);
        window.cameraHudComponent.showHideTopHeader(false);
      }
      else { // No more rounds
        // console.warn('winLevel() FINISH GAME.', this.currentLevel + 1, 'NOT <', Object.keys(Config.levelsData[Config.activeTheme]).length);
        this.winGame();
      }
    }, 1500);
  },

  nextLevel() {
    this.offsetPlayerHealth((this.getCurrentLevelData().playerHealthPercentRecoveryAfterWin / 100) * Config.playerMaxHealth); // Heal player
    this.currentLevel += 1;
    this.resetRoundData();
    this.startRound();
  },

  winGame() {
    this.wonGame = true;
    this.finishGame();
    this.checkHighScore(true);
  },

  loseGame() {
    this.finishGame();
    window.enemyMonsterRef.setAttackEnabled(false); // Enemy still alive, stop it
    this.checkHighScore(false);
  },

  finishGame() {
    this.setGameStatus(Config.gameStatus.finished);
    XR8.stop(); // Disable AR tracking
  },

  checkHighScore(win = true) {
    FirebaseManager.GetLeaderboard().then((result) => {
      // console.log('checkHighScore() - result:', result, this.currentScore, '>', result[result.length - 1].score);
      if (this.currentScore > 0 && (result.length === 0 || result.length < Config.leaderboardMaxEntries || this.currentScore > result[result.length - 1].score)) {
        // console.log('MADE IT TO THE LEADERBOARD.', this.currentScore, '>', result[result.length - 1].score);
        window.cameraHudComponent.showHideEnterNameScreen(true);
      }
      else if (win) { // Win, no record
        console.log('checkHighScore() - Win, no record');
        // window.cameraHudComponent.showHideWinScreen(true); // TODO? Use this if there are no more levels left?
        window.cameraHudComponent.showHideGameOverScreen(true);
      }
      else { // Lose, no record
        console.log('checkHighScore() - Lose, no record');
        window.cameraHudComponent.showHideEndGameScreen(true);
      }
    });
    //
    const previousHighScore = localStorage.getItem('highScore');
    if (previousHighScore === null || this.currentScore > previousHighScore) { // Save local score (Not used anymore)
      if (!localStorage.getItem('playerID')) {
        localStorage.setItem('playerID', window.gameManager.el.object3D.uuid);
      }
      localStorage.setItem('highScore', this.currentScore);
    }
  },

  sendScore(playerName) {
    FirebaseManager.AddUserInLeaderboard(playerName, this.currentScore);
  },

  getCurrentLevelData() {
    return Config.levelsData[Config.activeTheme][this.currentLevel];
  },

  refreshExperience() {
    console.log('gameManager refreshExperience()');
  },

  setPaused(newValue = true) {
    this.setGameplayPaused(newValue);
    window.cameraHudComponent.showHidePause(this.isPaused);
  },

  setGameplayPaused(newValue = true) {
    this.isPaused = newValue;
    if (this.isPaused) {
      gsap.globalTimeline.pause();
    }
    else {
      gsap.globalTimeline.play();
    }
    this.el.sceneEl.emit('onSetPaused', { isPaused: this.isPaused }, false);
  }
};

export { gameManagerComponent };
