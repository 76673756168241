/* eslint-disable object-shorthand */
import gsap from 'gsap';
import Config from './config.js';
import Utils from './utils.js';

const SwipeDirections = Object.freeze({
  right: Symbol('right'),
  left: Symbol('left'),
});

const enemyProjectileComponent = {
  schema: {
    name: { default: 'enemyProjectile' },
  },

  init() {
    this.deflectTweenDuration = 1.0;
    this.checkTargetTimer = 0;
    this.cameraEl = this.el.sceneEl.querySelector('#camera');
    this.cameraObj = this.cameraEl.object3D;

    // Input:
    this.el.classList.add('raycastInteractable');
    this.el.addEventListener('mouseenter', (evt) => {
      setTimeout(() => { // Wait 1 frame so that window.inputManager.currentMousePosition is updated
        if (this.deflected) return;
        this.colliderDetectedRaycast = true;
        // console.log('##-##-## 000 AFRAME [mouseenter] swipe - evt:', evt, 'TOUCHES:', evt.detail.touchEvent, ' --- detail.intersection', evt.detail.intersection);
        window.inputManager.initialMousePosition.set(window.inputManager.currentMousePosition.x, window.inputManager.currentMousePosition.y);
        // console.log(`%c 111 -- AFRAME [mouseenter] swipe :: initialMousePosition: ${window.inputManager.initialMousePosition.x} ${window.inputManager.initialMousePosition.y} __ currentMousePosition: ${window.inputManager.currentMousePosition.x} ${window.inputManager.currentMousePosition.y} __ colliderDetectedRaycast: ${this.colliderDetectedRaycast}`, 'background: #000; color: #FF789A');
      }, 1);
    });
    this.el.addEventListener('mouseleave', (evt) => {
      if (this.deflected || !this.colliderDetectedRaycast) return;
      this.colliderDetectedRaycast = false;
      // console.log(`%c 222 A -- AFRAME [mouseleave] swipe :: initialMousePosition: ${window.inputManager.initialMousePosition.x} ${window.inputManager.initialMousePosition.y} __ currentMousePosition: ${window.inputManager.currentMousePosition.x} ${window.inputManager.currentMousePosition.y} __ colliderDetectedRaycast: ${this.colliderDetectedRaycast}`, 'background: #000; color: #FF789A');
      this.trySwipe();
    });
    this.el.addEventListener('mouseup', (evt) => {
      if (this.deflected || !this.colliderDetectedRaycast) return;
      this.colliderDetectedRaycast = false;
      // console.log(`%c 222 B -- AFRAME [mouseup] swipe :: initialMousePosition: ${window.inputManager.initialMousePosition.x} ${window.inputManager.initialMousePosition.y} __ currentMousePosition: ${window.inputManager.currentMousePosition.x} ${window.inputManager.currentMousePosition.y} __ colliderDetectedRaycast: ${this.colliderDetectedRaycast}`, 'background: #000; color: #FF789A');
      this.trySwipe();
    });

    // 3D model:
    this.glbModelEl = document.createElement('a-entity');
    this.glbModelEl.setAttribute('gltf-model', '#enemy-projectile-glb');
    this.glbModelEl.object3D.originalScale = Config.projectileModelScale;
    this.glbModelEl.object3D.scale.set(
      this.glbModelEl.object3D.originalScale * 0.57,
      this.glbModelEl.object3D.originalScale * 0.57,
      this.glbModelEl.object3D.originalScale,
    );
    // this.glbModelEl.object3D.position.y = -0.17;
    this.el.appendChild(this.glbModelEl);

    // Particles:
    /* this.particlesEl = document.createElement('a-entity');
    this.particlesEl.setAttribute('particle-system', {
      color: ['#ffffff', '#ffffff'],
      enabled: false,
      type: 3,
      radius: 0.05,
      rotationAngleSpread: 0,
      positionSpread: { x: 0, y: 0, z: 0 },
      size: [0.4, 0.7],
      // sizeSpread: [0.1],
      blending: 1,
      maxAge: 1,
      maxAgeSpread: 0.7,
      particleCount: 7,
      duration: 5,
      velocityValue: { x: 0, y: 0, z: -1.1 },
      velocitySpread: { x: 0.05, y: 0.05, z: 0 },
      accelerationValue: { x: 0, y: 0, z: 0 },
      accelerationSpread: { x: 0, y: 0, z: 0 },
      dragValue: 0,
      angleSpread: 7,
      opacity: [0, 1, 1, 0],
    });
    this.el.appendChild(this.particlesEl);

    // Shard Particles:
    this.shardParticlesEl = document.createElement('a-entity');
    this.shardParticlesEl.setAttribute('particle-system', {
      color: ['#ffffff', '#ffffff'],
      enabled: false,
      type: 3,
      radius: 0.05,
      rotationAngleSpread: 0,
      positionSpread: { x: 0, y: 0, z: 0 },
      size: [0.3, 0.5],
      // sizeSpread: [0.1],
      blending: 1,
      maxAge: 0.9,
      maxAgeSpread: 0.2,
      particleCount: 2,
      duration: 0.1,
      velocityValue: { x: 0, y: 1, z: 0 },
      velocitySpread: { x: 0.4, y: 0.4, z: 0.4 },
      accelerationValue: { x: 0, y: -0.9, z: 0 },
      accelerationSpread: { x: 0, y: 0, z: 0 },
      dragValue: 0,
      angleSpread: 7,
      opacity: [0, 1, 1, 0],
      isShards: true,
    });
    this.el.appendChild(this.shardParticlesEl); */
    setTimeout(() => {
      this.smokeParticleSystem = window.particleManager.createParticleSystem_smoke(this.el.object3D);
      this.smokeParticleSystem.canUpdate = false;
      this.shardsParticleSystem = window.particleManager.createParticleSystem_shards(this.el.object3D);
      this.shardsParticleSystem.canUpdate = false;
    }, 2000);

    if (Utils.paramIsEnabled('enableDebug')) {
      this.el.components.material.material.opacity = 0.15;
    }

    this.subscribeToEvents();
  },

  subscribeToEvents() {
    this.el.sceneEl.addEventListener('onWinLevel', this.onWinLevel.bind(this));
    this.el.addEventListener('model-loaded', this.modelFinishedLoading.bind(this));
  },

  modelFinishedLoading() {
    this.el.object3D.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        if (child.material.name === 'Shard_blank' && !child.material.alreadyAdjusted) {
          child.material.alreadyAdjusted = true;
          this.matRef = child.material;

          // Update normal map:
          if (!this.el.sceneEl.shardNormalTexture) { // Save it only once
            this.el.sceneEl.shardNormalTexture = new THREE.Texture(this.el.sceneEl.querySelector('#shard-normal-texture'));
          }
          this.el.sceneEl.shardNormalTexture.encoding = 3000;
          this.el.sceneEl.shardNormalTexture.flipY = false;
          this.el.sceneEl.shardNormalTexture.needsUpdate = true;
          // console.log('enemyProjectileComponent this.el.object3D.traverse - normalTexture:', this.el.sceneEl.shardNormalTexture);
          // console.log('enemyProjectileComponent this.el.object3D.traverse - child.material:', child.material);
          child.material.normalMap = this.el.sceneEl.shardNormalTexture;
          child.material.normalScale.set(1, 1);

          child.material.needsUpdate = true;
        }
      }
    });
  },

  onWinLevel() {
    this.deflect(true, null);
  },

  tick(time, deltaTime) {
    if (!window.gameManager.isPaused && !this.deflected) {
      this.performMovement(deltaTime);
    }
  },

  performMovement(deltaTime) {
    // Get target:
    if (this.checkTargetTimer >= 300) {
      this.updateLookAtLerp(true);
      this.checkTargetTimer = 0;
    }
    this.checkTargetTimer += deltaTime;

    // Move towards target:
    this.el.object3D.translateZ(this.movementSpeed * 0.001 * deltaTime);

    this.checkCollisions();
  },

  updateLookAtLerp(smooth = true) {
    const dist = this.el.object3D.position.distanceTo(this.cameraObj.position);
    /* const lerpDuration = window.gameManager.currentLevel < 3 ? 2 : 1;
    this.el.components['look-at'].setLerpFactor(
      Math.max(0.0009, Math.min((0.12 / (dist + 2.0)) - 0.018, 0.03)), // Smooth look-at at a distance, stronger effect when closer
      smooth ? lerpDuration : 0,
    ); */
    this.el.components['look-at'].incrementLerpFactor(window.gameManager.getCurrentLevelData().enemyProjectileLerpStrength / dist); // Smooth look-at first, increment over time
  },

  restartStatus() {
    this.canBeDeflected = false;
    this.deflected = false;
    this.colliderDetectedRaycast = false;
    this.checkTargetTimer = 0;
    this.el.classList.add('raycastInteractable');
    this.alreadyHitPlayer = false;
    this.movementSpeed = window.gameManager.getCurrentLevelData().enemyProjectileSpeed;
    let shardSpeedMult = Utils.getParamValue('shardSpeedMult');
    if (shardSpeedMult) shardSpeedMult = parseFloat(shardSpeedMult);
    if (typeof shardSpeedMult === 'number') {
      this.movementSpeed *= shardSpeedMult;
    }

    this.el.object3D.scale.set(1, 1, 1);
    this.glbModelEl.object3D.scale.set(
      this.glbModelEl.object3D.originalScale * 0.57,
      this.glbModelEl.object3D.originalScale * 0.57,
      this.glbModelEl.object3D.originalScale,
    );
    this.glbModelEl.object3D.rotateZ(Math.PI * Math.random());
    this.updateColor();
    /* this.particlesEl.components['particle-system'].stopParticles();
    this.particlesEl.components['particle-system'].startParticles();
    this.shardParticlesEl.components['particle-system'].stopParticles(); */
    if (this.smokeParticleSystem) {
      this.smokeParticleSystem.canUpdate = true;
      window.particleManager.setParticleEmit(this.smokeParticleSystem, true);
    }
    if (this.shardsParticleSystem) {
      this.shardsParticleSystem.canUpdate = false;
      window.particleManager.setParticleEmit(this.shardsParticleSystem, false);
    }
    if (this.moveTween) this.moveTween.kill();
    if (this.rotateTween) this.rotateTween.kill();
  },

  updateColor() {
    if ( // Cache the texture
      !this.el.sceneEl.shardTexture // Texture not loaded before
      || `#${this.el.sceneEl.shardTexture.source.data.id}` !== window.gameManager.getCurrentLevelData().shardModelTexture // Trying to load a different texture
    ) {
      if (this.el.sceneEl.shardTexture) {
        this.el.sceneEl.shardTexture.dispose();
      }
      this.el.sceneEl.shardTexture = new THREE.Texture(this.el.sceneEl.querySelector(window.gameManager.getCurrentLevelData().shardModelTexture));
      this.el.sceneEl.shardTexture.encoding = 3001;
      this.el.sceneEl.shardTexture.flipY = false;
      this.el.sceneEl.shardTexture.needsUpdate = true;
    }
    this.matRef.map = this.el.sceneEl.shardTexture;
    this.matRef.needsUpdate = true;
  },

  startChasing(originObject3D) {
    this.restartStatus();
    const worldPosition = new THREE.Vector3();
    originObject3D.getWorldPosition(worldPosition);
    this.el.object3D.position.set(
      worldPosition.x,
      worldPosition.y + (4.4 * Config.modelScale * window.gameManager.getCurrentLevelData().enemyScale),
      worldPosition.z,
    );
    gsap.from(this.glbModelEl.object3D.scale, {
      x: 0.001,
      y: 0.001,
      z: 0.001,
      duration: 0.15,
      ease: 'none',
    });

    // Initial direction with a random variation:
    this.targetPosition = this.cameraObj.position.clone();
    this.enemyProjectileInitialDirectionVariation = window.gameManager.getCurrentLevelData().enemyProjectileInitialDirectionVariation;
    let shardCurveMult = Utils.getParamValue('shardCurveMult');
    if (shardCurveMult) shardCurveMult = parseFloat(shardCurveMult);
    if (typeof shardCurveMult === 'number') {
      this.enemyProjectileInitialDirectionVariation *= shardCurveMult;
    }
    this.targetPosition.x += THREE.MathUtils.randFloat(
      -this.enemyProjectileInitialDirectionVariation,
      this.enemyProjectileInitialDirectionVariation,
    );
    this.targetPosition.z += THREE.MathUtils.randFloat(
      -this.enemyProjectileInitialDirectionVariation,
      this.enemyProjectileInitialDirectionVariation,
    );
    // this.el.object3D.lookAt(this.targetPosition);
    this.el.components['look-at'].setTrackingEnabled(true);
    this.el.components['look-at'].setTrackingTarget(this.targetPosition);
    // this.el.object3D.lookAt(this.cameraObj.position);
    this.el.components['look-at'].setLerpFactor(0, 0);
    this.el.components['look-at'].setYOffset(-0.04);
    setTimeout(() => {
      this.updateLookAtLerp(false);
      this.el.components['look-at'].setTrackingTarget(this.cameraEl);
    }, 100);
    // Debug:
    /* const geometry = new THREE.BoxGeometry(0.04, 0.04, 0.04);
    const material = new THREE.MeshBasicMaterial({ color: 0xf0ff00, transparent: true, opacity: 0.3 });
    const debugCube = new THREE.Mesh(geometry, material);
    debugCube.position.copy(this.targetPosition);
    setTimeout(() => {
      debugCube.material.color.r = 0;
    }, 2000);
    this.el.sceneEl.object3D.add(debugCube); */

    /*
    // Move towards target:
    this.targetPosition = this.cameraObj.position.clone();
    this.targetPosition.y -= 0.09;
    const distanceToMovePastCamera = 4;
    const direction = this.targetPosition.clone().sub(this.el.object3D.position).normalize();
    const extendedTargetPosition = this.targetPosition.add(direction.multiplyScalar(distanceToMovePastCamera));
    // console.log('shoot - targetPosition:', targetPosition, ', extendedTargetPosition:', extendedTargetPosition);
    this.el.object3D.lookAt(extendedTargetPosition);

    const tweenDuration = this.el.object3D.position.distanceTo(extendedTargetPosition) / window.gameManager.getCurrentLevelData().enemyProjectileSpeed;
    this.moveTween = gsap.to(this.el.object3D.position, {
      x: extendedTargetPosition.x,
      y: extendedTargetPosition.y,
      z: extendedTargetPosition.z,
      duration: tweenDuration,
      ease: 'none',
      onComplete: () => {
        this.disappear();
      },
      onUpdate: this.checkCollisions.bind(this),
    });
    */

    const randomSign = Math.random() < 0.5 ? '+=3' : '-=3';
    this.rotateTween = gsap.to(this.glbModelEl.object3D.rotation, {
      z: `${randomSign}=${Math.PI * 2}`,
      duration: THREE.MathUtils.randFloat(3, 7),
      ease: 'none',
      repeat: -1,
    });

    if (this.enableDeflecterTimeout) clearTimeout(this.enableDeflecterTimeout);
    this.enableDeflecterTimeout = setTimeout(() => {
      this.canBeDeflected = true;
    }, 200);
  },

  checkCollisions() {
    if (this.el.object3D.position.distanceTo(this.cameraObj.position) < Config.enemyProjectileDamageRadius) {
      this.hitPlayer();
    }
  },

  hitPlayer() {
    if (this.alreadyHitPlayer) return;
    this.alreadyHitPlayer = true;
    console.log('hitPlayer()');
    this.disappear();
    window.gameManager.hitPlayer();
  },

  trySwipe() {
    if (window.gameManager.isPaused || this.deflected || !this.canBeDeflected) return;
    if (window.gameManager.isCrackPreventingInput(window.inputManager.currentMousePosition.y)) return;
    let swipeDirection = null;
    // console.log('trySwipe - bulletEl.initialMousePosition.x - window.inputManager.currentMousePosition.x =', window.inputManager.initialMousePosition.x - window.inputManager.currentMousePosition.x);
    if (window.inputManager.currentMousePosition.x > window.inputManager.initialMousePosition.x + this.getswipeMinDistanceFactor()) {
      // console.warn('333 Swipe right. currentMousePosition.x', window.inputManager.currentMousePosition.x, '> initialMousePosition.x', window.inputManager.initialMousePosition.x + this.getswipeMinDistanceFactor(), '(', window.inputManager.initialMousePosition.x, '+', this.getswipeMinDistanceFactor(), ')');
      swipeDirection = SwipeDirections.right;
    }
    else if (window.inputManager.currentMousePosition.x < window.inputManager.initialMousePosition.x - this.getswipeMinDistanceFactor()) {
      // console.warn('333 Swipe left. window.inputManager.currentMousePosition.x', window.inputManager.currentMousePosition.x, '< initialMousePosition.x', window.inputManager.initialMousePosition.x - this.getswipeMinDistanceFactor(), '(', window.inputManager.initialMousePosition.x, '-', this.getswipeMinDistanceFactor(), ')');
      swipeDirection = SwipeDirections.left;
    }
    else {
      // console.warn('333 No Swipe, not enough swipe distance');
    }
    if (swipeDirection) {
      this.deflect(false, swipeDirection);
    }
  },

  getswipeMinDistanceFactor() {
    return window.innerWidth * Config.swipeMinDistanceFactor;
  },

  deflect(isEndOfLevel = true, swipeDirection = null) {
    if (this.deflected) return;
    this.deflected = true;
    if (this.moveTween) this.moveTween.kill();
    if (this.rotateTween) this.rotateTween.kill();
    // this.particlesEl.components['particle-system'].stopParticles();
    if (swipeDirection) {
      this.deflectTween(swipeDirection);
    }
    // this.shardParticlesEl.components['particle-system'].startParticles();
    if (this.shardsParticleSystem) {
      this.shardsParticleSystem.canUpdate = true;
      window.particleManager.setParticleEmit(this.shardsParticleSystem, true);
      setTimeout(() => {
        window.particleManager.setParticleEmit(this.shardsParticleSystem, false);
      }, 100);
    }
    this.disappear(this.deflectTweenDuration);
    if (!isEndOfLevel) {
      window.audioManager.playSound(['swipeShardAudio_1', 'swipeShardAudio_2']);
      this.el.sceneEl.emit('onProjectileDeflected', { swipeDirection: swipeDirection }, false);
    }
  },

  deflectTween(swipeDirection) {
    // Create a vector representing the local right direction
    const localEndPosition = new THREE.Vector3(
      swipeDirection === SwipeDirections.right ? 0.4 : -0.4,
      -0.2,
      0,
    );

    // Apply the object's local rotation to this vector to transform it to world space
    localEndPosition.applyQuaternion(this.el.object3D.quaternion);

    // Now, add 1 unit in the local right direction to the projectile's current position
    const newPosition = new THREE.Vector3();
    newPosition.copy(this.el.object3D.position).add(localEndPosition);

    /* this.deflectTimeline = gsap.timeline({});
    this.deflectTimeline.to(this.el.object3D.position, { x: newPosition.x, duration: 1.5, ease: 'sine.out' });
    this.deflectTimeline.to(this.el.object3D.position, { y: newPosition.y, duration: 1.5, ease: 'back.in' });
    this.deflectTimeline.to(this.el.object3D.position, { z: newPosition.z, duration: 1.5, ease: 'sine.out' }); */

    this.deflectTwnXZ = gsap.to(this.el.object3D.position, {
      x: newPosition.x,
      z: newPosition.z,
      duration: this.deflectTweenDuration,
      ease: 'none',
    });
    this.deflectTwnY = gsap.to(this.el.object3D.position, {
      y: newPosition.y,
      duration: this.deflectTweenDuration,
      ease: 'sine.in',
      onComplete: () => {
      },
    });
  },

  disappear(delay = 0) {
    this.el.classList.remove('raycastInteractable');
    this.el.components['look-at'].setTrackingEnabled(false);
    gsap.to(this.glbModelEl.object3D.scale, {
      x: 0.001,
      y: 0.001,
      z: 0.001,
      duration: 0.2,
      ease: 'none',
    });
    if (this.smokeParticleSystem) window.particleManager.setParticleEmit(this.smokeParticleSystem, false);
    setTimeout(() => {
      // console.log('disappear - this:', this, ', this.el:', this.el, ', pool__enemy_projectile:', this.el.sceneEl.components.pool__enemy_projectile);
      if (this.smokeParticleSystem) this.smokeParticleSystem.canUpdate = false;
      if (this.shardsParticleSystem) this.shardsParticleSystem.canUpdate = false;
      this.el.sceneEl.components.pool__enemy_projectile.returnEntity(this.el); // Return to pool
    }, delay * 1000);
  },
};

export { enemyProjectileComponent };
