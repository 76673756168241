import { initializeApp } from 'firebase/app';
import { addDoc, collection, getDocs, getFirestore, limit, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import Utils from './utils.js';
import Config from './config.js';

const firebaseConfig = {
  apiKey: 'AIzaSyAluINNUSg81cg-Y-myzzcBdqxUKEmQwQk',
  authDomain: 'metallic-menace.firebaseapp.com',
  projectId: 'metallic-menace',
  storageBucket: 'metallic-menace.appspot.com',
  messagingSenderId: '161060460341',
  appId: '1:161060460341:web:2e9e4460add9b99583de7c',
  measurementId: 'G-3R0S9QXYB0',
};

const app = initializeApp(firebaseConfig);
let authCompleted = false;
// console.log('FirebaseManager app:', app);

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    console.log('onAuthStateChanged() - user:', user);
  } else {
    // User is signed out
  }
});
signInAnonymously(auth)
  .then(() => {
    // Signed in..
    authCompleted = true;
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log('signInAnonymously() errorCode:', errorCode, ', errorMessage:', errorMessage);
  });

const getLeaderboardTableName = () => (Utils.isLocalhost() ? 'leaderboard_dev' : 'leaderboard');

const IsUsernameExist = async (username) => {
  const db = getFirestore(app);
  const querySnapshot = await getDocs(query(collection(db, getLeaderboardTableName()), where('username', '==', username)));
  return querySnapshot.size > 0;
};

const AddUserInLeaderboard = async (username, score) => {
  const db = getFirestore(app);
  console.log(`FirebaseManager AddUserInLeaderboard - username: "${username}", score: ${score}`);

  try {
    /* const isUsernameExist = await IsUsernameExist(username);
    if (isUsernameExist) {
      const querySnapshot = await getDocs(query(collection(db, getLeaderboardTableName()), where('username', '==', username)));
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, { score: score });
      });
      return true;
    } */
    const docRef = await addDoc(collection(db, getLeaderboardTableName()), {
      username: username,
      score: score,
      userID: localStorage.getItem('playerID'),
    });
    return true;
  } catch (e) {
    console.error('Error adding document: ', e);
  }

  return false;
};

const GetLeaderboard = async () => {
  const db = getFirestore(app);
  const querySnapshot = await getDocs(query(collection(db, getLeaderboardTableName()), orderBy('score', 'desc'), limit(Config.leaderboardMaxEntries)));
  let leaderboard = [];
  querySnapshot.forEach((doc) => {
    leaderboard.push(doc.data());
  });
  return leaderboard;
};

const addRandomScores = () => { // For debugging
  for (let i = 0; i < 20; i++) {
    AddUserInLeaderboard(
      Math.random().toString(36).substring(2, 7),
      THREE.MathUtils.randInt(10, 500) * 100,
    );
  }
};
// addRandomScores();

export { AddUserInLeaderboard, GetLeaderboard, IsUsernameExist };
