import Utils from './utils.js';
import tutorialImage1 from '../../assets/ui-images/tutorial_shards.png';
import tutorialImage2 from '../../assets/ui-images/tutorial_laser.png';
import tutorialImage3 from '../../assets/ui-images/tutorial_health.png';

import monsterTitleBgImage1 from '../../assets/ui-images/round_intro_bottom_bg_0.png';
import monsterTitleBgImage2 from '../../assets/ui-images/round_intro_bottom_bg_1.png';
import monsterTitleBgImage3 from '../../assets/ui-images/round_intro_bottom_bg_2.png';
import monsterTitleBgImage4 from '../../assets/ui-images/round_intro_bottom_bg_3.png';
import monsterTitleBgImage5 from '../../assets/ui-images/round_intro_bottom_bg_4.png';

import monsterTitleBgImageShards1 from '../../assets/ui-images/round_intro_bottom_bg_0_shards.png';
import monsterTitleBgImageShards2 from '../../assets/ui-images/round_intro_bottom_bg_1_shards.png';
import monsterTitleBgImageShards3 from '../../assets/ui-images/round_intro_bottom_bg_2_shards.png';
import monsterTitleBgImageShards4 from '../../assets/ui-images/round_intro_bottom_bg_3_shards.png';
import monsterTitleBgImageShards5 from '../../assets/ui-images/round_intro_bottom_bg_4_shards.png';

import monsterTitleTextImage1 from '../../assets/ui-images/round_intro_bottom_text_0.png';
import monsterTitleTextImage2 from '../../assets/ui-images/round_intro_bottom_text_1.png';
import monsterTitleTextImage3 from '../../assets/ui-images/round_intro_bottom_text_2.png';
import monsterTitleTextImage4 from '../../assets/ui-images/round_intro_bottom_text_3.png';
import monsterTitleTextImage5 from '../../assets/ui-images/round_intro_bottom_text_4.png';

import enemyHealthBar1 from '../../assets/ui-images/health_bars/enemy_health_bar_0.png';
import enemyHealthBar2 from '../../assets/ui-images/health_bars/enemy_health_bar_1.png';
import enemyHealthBar3 from '../../assets/ui-images/health_bars/enemy_health_bar_2.png';
import enemyHealthBar4 from '../../assets/ui-images/health_bars/enemy_health_bar_3.png';
import enemyHealthBar5 from '../../assets/ui-images/health_bars/enemy_health_bar_4.png';

import enemyHealthBarSlider1 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_0.png';
import enemyHealthBarSlider2 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_1.png';
import enemyHealthBarSlider3 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_2.png';
import enemyHealthBarSlider4 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_3.png';
import enemyHealthBarSlider5 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_4.png';

import enemyHealthBarSliderLow1 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_0_low.png';
import enemyHealthBarSliderLow2 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_1_low.png';
import enemyHealthBarSliderLow3 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_2_low.png';
import enemyHealthBarSliderLow4 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_3_low.png';
import enemyHealthBarSliderLow5 from '../../assets/ui-images/health_bars/enemy_health_bar_slider_4_low.png';

export default class Config {
  constructor() {
    throw new Error('AbstractClassError');
  }
}

Config.gameStatus = Object.freeze({
  stopped: 'stopped',
  playing: 'playing',
  finished: 'finished',
});

Config.customComponentsNames = [];
Config.leaderboardMaxEntries = 20;
Config.desktopCameraHeight = 1;
Config.markerScale = 1;
Config.modelScale = 0.05;
Config.modelScale *= Utils.getParamValue('modelScale') || 1;
Config.projectileModelScale = 0.15;
Config.playerMaxHealth = 10;
Config.scorePerDeflect = 100;
Config.scorePerHit = 50;
Config.scoreForNoDagameReceivedLevel = 5000;
Config.buttonPressAmountForComboAttack = 20;
Config.scoreForComboAttack = 5000;
Config.swipeMinDistanceFactor = 0.01;
Config.enemyProjectileDamageRadius = 0.35;
Config.timeToDepleteLaserCharge = 5;
Config.musicFadeDuration = Utils.getParamValue('musicFadeDuration') || 1;
Config.musicFadeDuration = Math.max(0, Config.musicFadeDuration);
Config.loadingMessages = [
  'Tap the power-up laser repeatedly to defeat Menaces',
  'Vaping can expose your lungs to toxic metals',
  'Defeat each Menace to increase your energy meter',
  'Vaping can contain chromium, nickel, and lead',
  'Score enough points to make it to the leaderboard',
];
Config.shareURL = 'https://metallicmenace.fandom.com/?utm_source=social&utm_medium=game&utm_campaign=social';
Config.shareText = "I'm challenging the Metallic Menace and racked up [score-number] points. Are you ready to face the Metallic Menace? #MetallicMenace Play here: [game-url] Learn more about the facts of vaping: https://therealcost.betobaccofree.hhs.gov/vapes/real-facts";
Config.shareImageText = 'I scored [score-number] points<br/>in the ultimate showdown';
Config.winTitleText = 'VICTORY';
Config.winMessageText = 'Congratulations on your victory against the Menaces...for now. Stay vigilant against exposure to harmful metals such as chromium, nickel, and lead that can damage your lungs if you&nbsp;vape.';
Config.loseTitleText = 'TRY AGAIN!';
Config.loseMessageText = 'Play again to persevere and triumph against the Menace. Stay vigilant against exposure to harmful metals such as chromium, nickel, and lead that can damage your lungs if you&nbsp;vape.';
Config.defaultFactMessage = 'If you vape, you could be inhaling toxic metals like nickel, chromium, and lead that can damage your lungs.';

Config.tutorialPages = Object.freeze({
  0: {
    text: "Swipe to deflect the Menace's harmful attacks.",
    image: tutorialImage1,
  },
  1: {
    text: 'Defeat the Menace by tapping your laser power-up within a limited amount of time.',
    image: tutorialImage2,
  },
  2: {
    text: 'Monitor your energy meter as you confront each Menace through all five intense stages.',
    image: tutorialImage3,
  },
});

Config.levelsData = Object.freeze({
  base: { // Original theme. Reskins can be added here
    0: {
      levelName: 'Level 1',
      enemyName: 'The Nickel Nightmare',
      titleBgImage: monsterTitleBgImage1,
      titleBgImageShards: monsterTitleBgImageShards1,
      titleTextImage: monsterTitleTextImage1,
      enemyHealthBar: enemyHealthBar1,
      enemyHealthBarSlider: enemyHealthBarSlider1,
      enemyHealthBarSliderLow: enemyHealthBarSliderLow1,
      monsterModelTexture: '#monster-texture-0',
      shardModelTexture: '#shard-texture-0',
      enemyScale: 0.45,
      enemyDistance: 3.0,
      enemyDamage: 1,
      enemyMaxHealth: 12,
      enemyMovementSpeed: 0.8,
      monsterActionDelay: 300,
      enemyProjectileSpeed: 1.375,
      enemyProjectileInitialDirectionVariation: 2,
      enemyProjectileLerpStrength: 0.007,
      swipesToChargeLaser: 5,
      pointsPerWin: 1000,
      playerHealthPercentRecoveryAfterWin: 40,
      factMessage: 'If you vape, you can be exposed to toxic metals like nickel, that can damage your lungs.',
    },
    1: {
      levelName: 'Level 2',
      enemyName: 'The Lead Lurker',
      titleBgImage: monsterTitleBgImage2,
      titleBgImageShards: monsterTitleBgImageShards2,
      titleTextImage: monsterTitleTextImage2,
      enemyHealthBar: enemyHealthBar2,
      enemyHealthBarSlider: enemyHealthBarSlider2,
      enemyHealthBarSliderLow: enemyHealthBarSliderLow2,
      monsterModelTexture: '#monster-texture-1',
      shardModelTexture: '#shard-texture-1',
      enemyScale: 0.7,
      enemyDistance: 3.3,
      enemyDamage: 1,
      enemyMaxHealth: 16,
      enemyMovementSpeed: 1.2,
      monsterActionDelay: 0,
      enemyProjectileSpeed: 2.18,
      enemyProjectileInitialDirectionVariation: 0.875,
      enemyProjectileLerpStrength: 0.009,
      swipesToChargeLaser: 6,
      pointsPerWin: 2000,
      playerHealthPercentRecoveryAfterWin: 30,
      factMessage: 'If you vape, you can be exposed to toxic metals like lead, that can damage your lungs.',
    },
    2: {
      levelName: 'Level 3',
      enemyName: 'The Aluminum Abomination',
      titleBgImage: monsterTitleBgImage3,
      titleBgImageShards: monsterTitleBgImageShards3,
      titleTextImage: monsterTitleTextImage3,
      enemyHealthBar: enemyHealthBar3,
      enemyHealthBarSlider: enemyHealthBarSlider3,
      enemyHealthBarSliderLow: enemyHealthBarSliderLow3,
      monsterModelTexture: '#monster-texture-2',
      shardModelTexture: '#shard-texture-2',
      enemyScale: 1.0,
      enemyDistance: 4.0,
      enemyDamage: 1,
      enemyMaxHealth: 20,
      enemyMovementSpeed: 1.5,
      monsterActionDelay: 0,
      enemyProjectileSpeed: 2.9,
      enemyProjectileInitialDirectionVariation: 0.925,
      enemyProjectileLerpStrength: 0.01,
      swipesToChargeLaser: 6,
      pointsPerWin: 3000,
      playerHealthPercentRecoveryAfterWin: 20,
      factMessage: 'If you vape, you can be exposed to toxic metals like aluminum, that can damage your lungs.',
    },
    3: {
      levelName: 'Level 4',
      enemyName: 'The Chromium Chaos',
      titleBgImage: monsterTitleBgImage4,
      titleBgImageShards: monsterTitleBgImageShards4,
      titleTextImage: monsterTitleTextImage4,
      enemyHealthBar: enemyHealthBar4,
      enemyHealthBarSlider: enemyHealthBarSlider4,
      enemyHealthBarSliderLow: enemyHealthBarSliderLow4,
      monsterModelTexture: '#monster-texture-3',
      shardModelTexture: '#shard-texture-3',
      enemyScale: 1.25,
      enemyDistance: 4.0,
      enemyDamage: 1,
      enemyMaxHealth: 24,
      enemyMovementSpeed: 2.5,
      monsterActionDelay: 0,
      enemyProjectileSpeed: 3.25,
      enemyProjectileInitialDirectionVariation: 0.9,
      enemyProjectileLerpStrength: 0.013,
      swipesToChargeLaser: 6,
      pointsPerWin: 4000,
      playerHealthPercentRecoveryAfterWin: 10,
      factMessage: 'If you vape, you can be exposed to toxic metals like chromium, that can damage your lungs.',
    },
    4: {
      levelName: 'Level 5',
      enemyName: 'The Metallic Menace',
      titleBgImage: monsterTitleBgImage5,
      titleBgImageShards: monsterTitleBgImageShards5,
      titleTextImage: monsterTitleTextImage5,
      enemyHealthBar: enemyHealthBar5,
      enemyHealthBarSlider: enemyHealthBarSlider5,
      enemyHealthBarSliderLow: enemyHealthBarSliderLow5,
      monsterModelTexture: '#monster-texture-4',
      shardModelTexture: '#shard-texture-4',
      enemyScale: 1.65,
      enemyDistance: 4.2,
      enemyDamage: 1,
      enemyMaxHealth: 28,
      enemyMovementSpeed: 3,
      monsterActionDelay: 0,
      enemyProjectileSpeed: 3.7,
      enemyProjectileInitialDirectionVariation: 0.75,
      enemyProjectileLerpStrength: 0.015,
      swipesToChargeLaser: 6,
      pointsPerWin: 10000,
      playerHealthPercentRecoveryAfterWin: 0,
      factMessage: '',
    },
  },
});

Config.activeTheme = 'base';
